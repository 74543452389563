import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Root = styled.div<Props>`
    background: ${p => p.background === 'alt' ? p.theme.color.backgroundAlt :
        (p.background === 'none' ? 'none' : p.theme.color.background)};
    width: 100%;
`;
const Content = styled.div<{ full?: boolean }>`
    margin: 0 auto;
    padding: 0 ${p => `${p.theme.grid.containerPad}px`};
    max-width: ${p => p.full ? '100%' : `${p.theme.grid.containerWidth + 2 * p.theme.grid.containerPad}px`};
`;

const Container: FunctionComponent<Props> = ({ children, ...props }) => (
    <Root {...props}>
        <Content full={props.full}>{children}</Content>
    </Root>
);

interface Props {
    className?: string;
    background?: 'default' | 'none' | 'alt';
    full?: boolean;
}

export default Container;
