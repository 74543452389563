import { useEffect } from 'react';
import { useLocation } from 'react-use';
import { ClientConfig, useClientConfig } from '../ClientConfig';
import Cookies from 'js-cookie';
import { CookieConsent } from '../components/Cookie/CookieConsentContext';

const IS_CLIENT = typeof window !== 'undefined';

const gTag: any = IS_CLIENT && (window as any).gtag;
let initGaCalled = false; // handle enable -> disable -> enable

export function useGtag(cookieConsent: CookieConsent | null) {

    const clientConfig = useClientConfig();
    const { pathname } = useLocation();

    if (IS_CLIENT && clientConfig.gtagId) {
        // if (!gTag) {
        //     // gtag is not working for some reason if registered like this - therefore the registration is directly in the head (hbs)

        //     // const gtagScript = document.createElement('script');
        //     // gtagScript.setAttribute('async', 'true');
        //     // gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${clientConfig.gtagId}`);
        //     // document.head.appendChild(gtagScript);
        //     //  (window as any).dataLayer = (window as any).dataLayer || [];
        //     // gTag = function (...args: any[]) { (window as any).dataLayer.push(args) };
        // }

        useEffect(() => {
            if (isgTagEnabled(clientConfig) && cookieConsent?.webAnalysisGa) {
                ensureInit();
                gTag('config', clientConfig.gtagId, { 'page_path': location.pathname });
            }
        }, [pathname, cookieConsent]);
    }
}

export function gTagEnable(clientConfig: ClientConfig) {
    window[gTagDisableKey(clientConfig)] = false;
    ensureInit();
}

function ensureInit() {
    if (gTag && !initGaCalled) {
        gTag('js', new Date());
        initGaCalled = true;
    }
}

export function gTagDisable(clientConfig: ClientConfig) {
    const domain = getGaCookieDomain(document.location.hostname);

    window[gTagDisableKey(clientConfig)] = true;
    Object.keys(Cookies.get())
        .filter(isGaCookie)
        .forEach(cookieName => Cookies.remove(cookieName, { domain }));
}

export function isGaCookie(cookieName: string) {
    return /^(AMP_TOKEN|_gid|_ga(t|_.+|c_.+|t_.*)?|_dc_gtm_.*)$/gi.test(cookieName); // https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage
}

export function getGaCookieDomain(hostname: string) {
    // http://tfs.st.it.local/tfs/DefaultCollection/ST.MultiProject/_workitems/edit/26943
    const lastDotIndex = hostname.lastIndexOf('.');
    if (lastDotIndex < 0) {
        return hostname;
    }
    const nextDotIndex = hostname.lastIndexOf('.', lastDotIndex - 1);
    if (nextDotIndex < 0) {
        return `.${hostname}`;
    }
    return hostname.substring(nextDotIndex);
}

export function isgTagEnabled(clientConfig: ClientConfig) {
    return !window[gTagDisableKey(clientConfig)];
}

function gTagDisableKey(clientConfig: ClientConfig) {
    return `ga-disable-${clientConfig.gtagId}`; // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out
}