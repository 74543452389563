import { createContext, useContext } from 'react';

const GlobalDataContext = createContext<GlobalData>({
  jobs: {
    count: 0
  },
  globalNotification: {
    documents: null,
    allowedPaths: ['/'] // default landing page only
  }
});

export default GlobalDataContext;

export interface GlobalData {
  jobs: {
    count: number;
  };
  globalNotification: {
    documents: GlobalNotificationDocument[] | null,
    allowedPaths: string[]
  };
}

export type GlobalNotificationDocument = {
  alias: string;
  title: string;
  body: string;
};

export const useGlobalData = () => useContext(GlobalDataContext);
