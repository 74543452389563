import React, { useEffect, useState } from 'react';
import {
  Switch,
} from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { StyleSheetManager, ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';
import GlobalStyle from './theme/global-style';
import theme from './theme/theme';
import materialTheme from './theme/materialTheme';
import routes from './routes';
import { SnackbarProvider } from 'notistack';
import createAccountWebProxy, { CurrentUserViewModel } from './utils/accountWebProxy';
import { AuthenticationContext } from './components/auth/authenticationContext';
import { setLocale } from 'yup';
import { defaultValidationMessages } from './utils/validationSchema';
import ClientConfigContext, { ClientConfig } from './ClientConfig';
import GlobalDataContext, { GlobalData } from './GlobalData';
import CookieConsentContext from './components/Cookie/CookieConsentContext';
import gridPrefixStylisPlugin from './theme/plugins/gridPrefixStylisPlugin';

const App = (props: Props) => {
  const [user, setUser] = useState<CurrentUserViewModel | null>(null);
  const accountWebProxy = createAccountWebProxy({ user });
  const clientConfig: ClientConfig = props.staticContext && props.staticContext.clientConfig || (window as any).__CLIENT_CONFIG__;
  const globalData: GlobalData = props.staticContext && props.staticContext.globalData || (window as any).__GLOBAL_DATA__;

  const authenticationContextValue = {
    user,
    setUser,
  };

  useEffect(() => {
    // https://material-ui.com/guides/server-rendering/
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }

    // initial auto login
    // TODO: handle the last request
    accountWebProxy.getCurrentUser()
      .then(u => setUser(u));
  }, []);

  // used by Yup, when no message is provided
  setLocale(defaultValidationMessages);

  return (
    <GlobalDataContext.Provider value={globalData}>
      <ClientConfigContext.Provider value={clientConfig}>
        <CookieConsentContext>
          <AuthenticationContext.Provider value={authenticationContextValue}>
            <StyleSheetManager stylisPlugins={[gridPrefixStylisPlugin]}>
              <StyledThemeProvider theme={theme}>
                <MaterialThemeProvider theme={materialTheme}>
                  <SnackbarProvider maxSnack={3} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}>
                    <GlobalStyle />
                    <Switch>
                      {renderRoutes(routes)}
                    </Switch>
                  </SnackbarProvider>
                </MaterialThemeProvider>
              </StyledThemeProvider >
            </StyleSheetManager>
          </AuthenticationContext.Provider>
        </CookieConsentContext>
      </ClientConfigContext.Provider>
    </GlobalDataContext.Provider>
  );
};

export default App;

interface Props {
  staticContext?:
  {
    globalData: GlobalData;
    clientConfig: ClientConfig;
  };
}