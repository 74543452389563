const PROPERTY_DECLARATION = 1;

const REPLACE_MAP = new Map<string, string>([
    ['column-gap', 'grid-column-gap'],
    ['row-gap', 'grid-row-gap'],
    ['gap', 'grid-gap'],
]);

// based on https://github.com/email-types/stylis-plugin-mso/blob/canary/src/index.ts
const gridPrefixStylisPlugin = (
    context: number,
    content: string,
) => {
    if (context === PROPERTY_DECLARATION) {
        const [key, value] = content.trim().split(':');
        const property = REPLACE_MAP.get(key);
        if (property) {
            return `${property}:${value};${content}`;
        }
    }
    return content;
};

export default gridPrefixStylisPlugin as any; // as any - typings are wrong