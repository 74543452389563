import Switch from '@material-ui/core/Switch';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import media from '../../theme/media';
import { PrimaryButton, SecondaryButton, TextButton } from '../Button/Button';
import DialogPopup from '../DialogPopup';
import { FormTextButton } from '../form/Button';
import Form, { OnSubmitParams } from '../form/Form';
import FormSwitchField from '../form/FormSwitchField';
import StLink from '../StLink';
import { ACCEPT_ALL_COOKIE_CONSENT, COOKIE_GROUPS, DECLINE_ALL_COOKIE_CONSENT, useCookieConsent } from './CookieConsentContext';

const Root = styled.div`
  
`;
const Settings = styled.div`
      margin-top:30px;
`;
const ButtonRow = styled.div`
      display: grid;
      grid: "allow-all" max-content
            "decline-all"  max-content
            "settings" max-content 
            /max-content;
      gap: 15px;
      justify-content: center;
      margin-top: 30px;
      ${media.greaterThan('medium')`
            grid: "settings . decline-all allow-all" max-content / max-content auto max-content max-content;
            justify-content: stretch;
            margin-top: 50px;
      `}      
`;

const SettingsButton = styled(TextButton)`
      grid-area: settings;
`;
const SaveSettingsButton = styled(FormTextButton)`
      grid-area: settings;
`;
const DeclineAllButton = styled(SecondaryButton)`
      grid-area: decline-all;
`;
const AllowAllButton = styled(PrimaryButton)`
      grid-area: allow-all;
`;

const SettingsRow = styled.div`
      display: grid;
      grid: "setting category" max-content 
            "description description" max-content
            /min-content auto;
      column-gap: 45px;
      padding: ${p => p.theme.grid.gutter}px 0;
      &:not(:last-child){
            border-bottom: solid 1px currentColor;
      }
      ${media.greaterThan('medium')`
            padding: 12.5px 0;
            grid: "setting category description" max-content / 80px 200px auto;
            &:not(:last-child){
                  border-bottom: none;
            }
      `}   
`;
const SettingCol = styled.div`
      grid-area: setting;
`;
const CategoryCol = styled.div`
      grid-area: category;
      ${media.greaterThan('medium')`
            grid: "setting category description" max-content / 80px 200px auto;
      `} 
`;
const DescriptionCol = styled.div`
      grid-area: description;
`;
const SettingsHeaderRow = styled(SettingsRow)`
      display: none;
      font-weight: bold;
      ${media.greaterThan('medium')`
          display: grid;
      `}   
`;
const SettingsTable = styled.div`
      display:grid;
      font-size: ${p => p.theme.font.size.formRem}rem;
      margin-top: 5px;
      ${media.greaterThan('medium')`
            margin-top: 25px;
      `}   
`;
const Category = styled.span`
      font-weight: bold;
      ${media.greaterThan('medium')`
            font-weight: normal;
      `} 
`;

const CookieGroupSetting: FunctionComponent<CookieSettingsRowProps> = (props) => (<SettingsRow>
      <SettingCol>
            <FormSwitchField
                  disabled={props.required}
                  name={props.name}
                  label={props.label}
            />
      </SettingCol>
      <CategoryCol><Category>{props.label}</Category></CategoryCol>
      <DescriptionCol>{props.description}</DescriptionCol>
</SettingsRow>);

const CookieDialogPopup: FunctionComponent = () => {
      const [isOpen, setIsOpen] = useState(false); // disable for SSR
      const [areSettingsVisible, setAreSettingsVisible] = useState(false);
      const { cookieConsent, setCookieConsent, cookieConsentIsLoading } = useCookieConsent();

      useEffect(() => {
            if (!cookieConsent && !cookieConsentIsLoading) {
                  setIsOpen(true);
            }
      }, [cookieConsentIsLoading]);

      const saveSettings = ({ values }: OnSubmitParams) => {
            setCookieConsent(COOKIE_GROUPS.reduce((acc, group) => {
                  const groupValue = values[group.name];
                  const groupCookies = group.cookies.reduce((gAcc, cookie) => ({ ...gAcc, [cookie]: groupValue }), {});
                  return { ...acc, ...groupCookies };
            }, DECLINE_ALL_COOKIE_CONSENT));
            setIsOpen(false);
            return Promise.resolve();
      };
      const acceptAll = () => {
            setCookieConsent(ACCEPT_ALL_COOKIE_CONSENT);
            setIsOpen(false);
      };
      const declineAll = () => {
            setCookieConsent(DECLINE_ALL_COOKIE_CONSENT);
            setIsOpen(false);
      };

      return (
            <DialogPopup title={'Hinweis zu Cookies'} onClose={() => setIsOpen(false)} open={isOpen} maxWidth="lg" disableClose={true} hideClose>
                  <Root>
                        <Form
                              initialValues={COOKIE_GROUPS.reduce((acc, group) => ({ ...acc, [group.name]: group.required || false }), {})}
                              onSubmit={saveSettings}
                              width={12}
                        >

                              <p>Diese Webseite nutzt Cookies, um bestmögliche Funktionalität bieten zu können. Details zu den Cookies finden Sie in unserer <StLink to="/datenschutz">Cookie Policy</StLink> und <StLink to="/datenschutz">Datenschutzerklärung</StLink>. 
                              Sie können Ihre Präferenzen jederzeit anpassen, indem Sie unsere <StLink to="/datenschutz">Cookie Policy</StLink> besuchen.</p>
                              {areSettingsVisible && <Settings>
                                    <h2>Cookie Einstellungen</h2>
                                    <p>Wenn Cookies deaktiviert werden, können einige oder alle dieser Funktionen beeinträchtigt werden.</p>
                                    <SettingsTable>
                                          <SettingsHeaderRow>
                                                <SettingCol>Aus/Ein</SettingCol>
                                                <CategoryCol>Kategorie</CategoryCol>
                                                <DescriptionCol>Zweck</DescriptionCol>
                                          </SettingsHeaderRow>
                                          {COOKIE_GROUPS.map(group => <CookieGroupSetting key={group.name} {...group} />)}
                                    </SettingsTable>
                              </Settings>}
                              <ButtonRow>
                                    {areSettingsVisible && <SaveSettingsButton type="submit">Speichern</SaveSettingsButton>}
                                    {!areSettingsVisible && <SettingsButton onClick={() => setAreSettingsVisible(true)}>Einstellungen</SettingsButton>}
                                    <DeclineAllButton onClick={declineAll}>Alle Cookies Ablehnen</DeclineAllButton>
                                    <AllowAllButton onClick={acceptAll}>Alle Cookies Akzeptieren</AllowAllButton>
                              </ButtonRow>
                        </Form>
                  </Root>
            </DialogPopup >
      );

};

export default CookieDialogPopup;

interface CookieSettingsRowProps {
      required?: boolean;
      name: string;
      label: string;
      description: string;
}