import React, { FunctionComponent, useState } from 'react';
import PlayButtonAbsolute from '../PlayButtonAbsolute';
import styled from 'styled-components';
import media from 'styled-media-query';

const ImageWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 370px;
    cursor: pointer;
`;

const RefImage = styled.img<{ hover: boolean }>`
    display: block;
    object-fit: cover;
    && {
      width: 100%;
      height: 100%;
    }
    min-height: 300px;
    margin: 0;
    margin-bottom: 30%;
    ${media.greaterThan('small')`
      margin-bottom: 0;
    `};
    transition: opacity 0.5s;
    &:hover{
      opacity: 0.4;
    }
    opacity: ${p => p.hover ? 0.4 : 1};
`;

const alignment = {
    center: { top: 50, left: 50 },
    left: { top: 50, left: 0 },
    right: { top: 50, left: 100 },
    top: { top: 0, left: 50 },
    bottom: { top: 100, left: 50 }
};

const VideoTile: FunctionComponent<{ onClickFn: () => void, thumbnail: string, alt: string, align?: string | { top: number, left: number } | undefined }> = ({ onClickFn, thumbnail, alt, align }) => {

    const [hover, setHover] = useState(false);
  
    return (
      <ImageWrap>
        <RefImage hover={hover} onClick={() => onClickFn()} src={thumbnail} alt={alt} />
        <PlayButtonAbsolute onClick={onClickFn} onHover={setHover} position={ typeof(align) === 'string' ? alignment[`${align}`] : align } />
      </ImageWrap>
    );
  };

  export default VideoTile;