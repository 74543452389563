// keep external css imports here (if they are not going to be big)
// Also note, that during dev, they are part of SSR result and are rendered by webpack. This sometimes causes visual artifacts, but only during dev  http://tfs.st.it.local/tfs/DefaultCollection/ST.MultiProject/_workitems/edit/25625
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';

loadableReady().then(() => {
  hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('root')
  );
});

if (module.hot) {
  module.hot.accept();
}
