import React, { useEffect, useRef } from 'react';
import { connect } from 'formik';
import usePrevious from 'react-use/lib/usePrevious';

// based on https://github.com/jaredpalmer/formik/issues/1633#issuecomment-520121543 
const FormikObserver = (props) => {
    const { values } = props.formik;
    const prevValues = usePrevious(values);    

    useEffect(() => {
        if (props.onChange && prevValues) {
            props.onChange({ prevValues, nextValues: values });
        }
    }, [values]);

    return null;
};

export default connect(FormikObserver);