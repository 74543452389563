import styled from 'styled-components';

const CmsContent = styled.div`
  text-transform: none;
  h1,h2,h3,h4,p{
    text-transform: none;
  }
  figure.image{
    display: grid;
    grid-template-columns: 1fr fit-content 1fr;
    height: auto;
  }
  figcaption{
    display: block;
    grid-column: 2;
    font-size:smaller;
    font-style: italic;
  }
  img{
    grid-column: 2;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }
`;

export default CmsContent;