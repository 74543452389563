import { generateMedia } from 'styled-media-query';
import theme from './theme';

export const mediaBreakpoints = {
    hugeQHD: '2560px',
    huge: '1440px',
    large: '1170px',
    projectMobile: `${theme.grid.containerPad * 2 + theme.grid.gutter + 2 * theme.project.defaultCardWidth}px`, // break when two project tiles would not fit
    structureLarge: '1050px',
    medium: '768px',
    small: '450px',
    buttonRow: `${2 * theme.button.minWidth + theme.grid.gutter + 2 * theme.grid.containerPad}px`, // break when two button with min width would not fit in one row
    navMob: '1070px',
    footerMedium: '1070px',
    footerMob: '570px',
};

const media = generateMedia(mediaBreakpoints);

export default media;