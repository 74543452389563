import React, { FunctionComponent, useEffect, useState } from 'react';
import { useCookie, useSessionStorage } from 'react-use';
import styled from 'styled-components';
import { GlobalNotificationDocument, useGlobalData } from '../GlobalData';
import media from '../theme/media';
import MarkdownContent from './Cms/MarkdownContent';
import DialogPopup from './DialogPopup';
import { sortBy } from 'lodash';

const Root = styled.div`
    font-size: ${p => p.theme.font.size.mobileBodyMinRem}rem;
    ${media.greaterThan('small')`
            font-size: 1rem;
    `}
`;

const GlobalNotificationDialogPopup: FunctionComponent = () => {
      const [isOpen, setIsOpen] = useState(false); // disable for SSR
      const [globalNotificationCookie, setGlobalNotificationCookie] = useCookie('notifPopup');
      const [globalNotificationSession, setGlobalNotificationSession] = useSessionStorage('notifPopup'); // prevent popup to be shown for the current session even if the cookie expires

      const documents = useGlobalData().globalNotification?.documents;
      const allowedPaths = useGlobalData().globalNotification?.allowedPaths;
      const [documentContent, setDocumentContent] = useState<GlobalNotificationDocument | null>(null);

      function resolveRandomDocument(doc: any): GlobalNotificationDocument | null {
            return doc && doc[Math.floor(Math.random() * doc.length)];
      }

      function resolveDocument(gnCookie: any): GlobalNotificationDocument | null {
            if (gnCookie) {
                  const currentCookieValue = deserializeCookieValue(gnCookie);
                  const notAlreadyNotifiedDocuments = documents?.filter(doc => !currentCookieValue.some(c => c.alias === doc.alias));

                  return resolveRandomDocument(notAlreadyNotifiedDocuments);
            }
            return resolveRandomDocument(documents);
      }

      function getSerializedCookieValue(doc: GlobalNotificationDocument, gnCookie: any): string {
            const currentCookieValue = gnCookie ? deserializeCookieValue(gnCookie) : [];
            currentCookieValue.push({ alias: doc.alias, displayedOn: new Date(Date.now()) });
            return JSON.stringify(currentCookieValue);
      }

      function deserializeCookieValue(value: string): { alias: string, displayedOn: Date }[] {
            return JSON.parse(value);
      }

      function displayNotification(gnCookie: any) {
            const isNotNotifiedInThisSession = !globalNotificationSession;
            const doc = resolveDocument(gnCookie);
            if (doc && isNotNotifiedInThisSession && allowedPaths?.includes(window.location.pathname)) {
                  setDocumentContent(doc);
                  setIsOpen(true);
                  setGlobalNotificationCookie(getSerializedCookieValue(doc, gnCookie), {
                        expires: 14 // +14 days
                  });
                  setGlobalNotificationSession(true);
            }
      }

      function isGonePeriodAfterLastVisibleNotification(gnCookie: any) {
            const notifCookies: { alias: string, displayedOn: Date }[] = gnCookie && sortBy(deserializeCookieValue(gnCookie), ['displayedOn']);
            const oneHourToMs = 1 * 60 * 60 * 1000; // 1 hour
            const areAllOlder = notifCookies && notifCookies.every(c => (new Date(Date.now()).getTime() - new Date(c.displayedOn).getTime()) > oneHourToMs);
            return notifCookies === null || notifCookies && areAllOlder;
      }

      useEffect(() => {
            const isAnyDocument = !!documents?.length;
            const currentCookieValue = globalNotificationCookie && deserializeCookieValue(globalNotificationCookie) || [];
            const notAlreadyNotifiedDocuments = documents?.filter(doc => !currentCookieValue.some(c => c.alias === doc.alias));

            if (isAnyDocument && isGonePeriodAfterLastVisibleNotification(globalNotificationCookie) && notAlreadyNotifiedDocuments?.length) {
                  displayNotification(globalNotificationCookie);
            } else if (globalNotificationCookie && isGonePeriodAfterLastVisibleNotification(globalNotificationCookie) && !notAlreadyNotifiedDocuments?.length) {
                  // reset cookie
                  setGlobalNotificationCookie('', { expires: -1 });
                  displayNotification('');
            }
      }, []);

      return (
            <>
                  {!!documentContent && <DialogPopup title={documentContent.title} onClose={() => setIsOpen(false)} open={isOpen} maxWidth="md">
                        <Root>
                              <MarkdownContent body={documentContent!.body} />
                        </Root>
                  </DialogPopup>}
            </>
      );

};

export default GlobalNotificationDialogPopup;
