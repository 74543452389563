import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import StLink from './StLink';
import { pxToRem } from '../theme/theme';

const Root = styled.div`
    font-style: italic;
    font-size: ${pxToRem(16)}rem;
`;
const CookieBlockedMessage: FunctionComponent<{ anchor: string; }> = ({ anchor }) => (
    <Root>
        Diese Funktionalität ist aufgrund der nicht vorhandenen Zustimmung in der Cookie Policy nicht verfügbar. Sie können die Zustimmung <StLink to={`/datenschutz#${anchor}`}>hier</StLink> erteilen.
    </Root>
);

export default CookieBlockedMessage;
