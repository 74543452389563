import { createContext, useContext } from 'react';

const ClientConfigContext = createContext<ClientConfig>({
  baseUrl: 'foo',
  captchaSiteKey: 'foo',
  gtagId: null,
});

export default ClientConfigContext;

export interface ClientConfig {
  baseUrl: string;
  captchaSiteKey: string;
  gtagId: string | null;
}

export const useClientConfig = () => useContext(ClientConfigContext);
