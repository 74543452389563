import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Root = styled.svg<{ className?: string, isPath?: boolean, size?: string }>`
  display: inline-block;
  width: ${p => p.size ?? '1em'};
  height: ${p => p.size ?? '1em'};;
  fill: ${p => p.isPath ? 'none' : 'currentColor'};
  stroke: ${p => p.isPath ? 'currentColor' : 'none'};
`;

const Icon: FunctionComponent<IconProps> = ({ glyph, className, isPath, size, onClick }) =>
  (<Root className={className} isPath={isPath} size={size} onClick={onClick}>
    <use xlinkHref={`#${glyph.id}`} />
  </Root>);

export interface IconProps {
  glyph: IconGlyph;
  className?: string;
  isPath?: boolean;
  size?: string;
  onClick?: () => void;
}

export interface IconGlyph {
  id: string;
}
export default Icon;