// based on https://github.com/pruttned/pruttned.github.io/blob/src/src/components/menu-button.js

import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

const size = 27;

const Inner = styled.div`
    position: absolute;
    background: currentColor;
    top: ${size / 5 * 2}px;
    height: 2px;
    left: 0;
    width: 100%;
    transition: opacity .2s ease;
`;

const Root = styled.div<{ isOpen: boolean }>`
    position: relative;
    width: ${size}px;
    height: ${size}px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &::before,
    &::after {
        display: block;
        position: absolute;
        background: currentColor;
        height: 2px;
        left: 0;
        width: 100%;
        content: '';
        transition: transform .2s ease, top .1s ease .1s;
    }
    &::before {
        top: 0;
    }
    &::after {
        top: ${size / 5 * 4}px;
    }
    ${p => p.isOpen && css`
        ${Inner}{
            opacity: 0;
        }
        &::before,
        &::after {
            top: ${size / 5 * 2}px;
            transition: transform .1s ease .1s, top .2s ease;
        }
        &::after {
            transform: rotate(45deg);
        }
        &::before {
            transform: rotate(-45deg);
        }
    `}
    &:hover, &:focus, &:active {
        color: ${p => p.theme.color.primary};
    }
`;

const Hamburger: FunctionComponent<Props> = ({ isOpen = false, className, onClick }: any) => (
    <Root className={className} onClick={onClick} isOpen={isOpen} >
        <Inner />
    </Root>
);

interface Props {
    isOpen: boolean;
    className?: string;
    onClick?: () => void;
}

export default Hamburger;