import React, { ReactNode, ForwardRefRenderFunction, MouseEventHandler } from 'react';
import { Link, matchPath } from 'react-router-dom';
import routes from '../routes';
import findRoute from '../utils/findRoute';

const StLink: ForwardRefRenderFunction<HTMLAnchorElement, LinkProps> = ({ to, children, follow, className, external, externalTarget, onClick, replace }, ref) => {

    const toPath = typeof to === 'string' ? to as string : to.pathname;
    const preload: any = !external && toPath && findRoute(routes, toPath)?.preload;

    return (
        external ?
            <a href={typeof (to) === 'string' ? to as string : `${location.pathname}${location.search}`} ref={ref} target={externalTarget ?? '_blank'} className={className} rel={['noopener', !follow && 'nofollow'].filter(x => x).join(' ')}>{children}</a> :
            <Link to={to} ref={ref} className={className} onClick={onClick} onMouseOver={preload} replace={replace}>{children}</Link>
    );
};

export interface LinkProps {
    to: {
        pathname?: string;
        search?: string;
        state?: any;
    } | string;
    follow?: boolean;
    className?: string;
    external?: boolean;
    externalTarget?: '_self' | '_blank';
    children?: ReactNode;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    replace?: boolean;
    state?: any;
}

// forward ref - https://material-ui.com/guides/composition/#caveat-with-refs
export default React.forwardRef(StLink);
