import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useCookie } from 'react-use';
import { gTagDisable, gTagEnable } from '../../utils/gtag';
import { useClientConfig, ClientConfig } from '../../ClientConfig';

const COOKIE_CONSENT_VERSION = 1; // increase this version to invalidate all consents of all users (cookie consent popup will pop up to all users) - when eg. a new cookie has been added or a cookie policy document has been changed...

export const COOKIE_GROUPS: {
    required?: boolean;
    name: string;
    label: string;
    description: string;
    cookies: CookieConsentKeys[];
}[] = [
        {
            required: true,
            name: 'required',
            label: 'Erforderlich',
            description: 'Diese Cookies werden für eine reibungslose Funktion unserer Webseite benötigt. Funktional notwendige Cookies sind für die Nutzung unserer Webseite erforderlich. Eine Zustimmung ist nicht notwendig.',
            cookies: ['required']
        },
        {
            name: 'webAnalysis',
            label: 'Web-Analyse',
            description: 'Mittels pseudonymisierter Daten von Webseitenutzern kann der Nutzerfluss analysiert und beurteilt werden. Dies gibt uns die Möglichkeit, unsere Webseiteinhalte zu optimieren. (Cookies von US-Anbietern)',
            cookies: ['webAnalysisGa']
        },
        {
            name: 'videoEmbeds',
            label: 'Video-Embeds',
            description: 'Zur Anzeige und Abspielen von Videos werden Youtube und Vimeo Inhalte in die Webseite eingebettet. Diese Drittanbieter können zur Funktionsfähigkeit eigene Cookies verwenden, über die wir keinen Einfluss haben. (Cookies von US-Anbietern)',
            cookies: ['videoEmbedsVimeo', 'videoEmbedsYoutube']
        },
        {
            name: 'registrationToMagazine',
            label: 'Anmeldung zu Magazinverständigungen',
            description: 'Wir binden ein Formular zur Anmeldung für die Magazinverständigungen über unseren Dienstleiter Sendinblue ein. Dieser Drittanbieter kann zur Funktionsfähigkeit eigene Cookies verwenden, über die wir keinen Einfluss haben.   ',
            cookies: ['registrationToMagazine']
        },
        {
            name: 'externalAuthentication',
            label: 'Externe Authentifizierung',
            description: 'Wir bieten Ihnen die Möglichkeit einer Anmeldung über Externe Authetifizierungsanbieter. Diese Drittanbieter können zur Funktionsfähigkeit eigene Cookies verwenden, über die wir keinen Einfluss haben. (Cookies von US-Anbietern)',
            cookies: ['externalAuthenticationGoogle', 'externalAuthenticationFb']
        }
    ];

export type CookieConsentKeys = keyof CookieConsent;

export const ACCEPT_ALL_COOKIE_CONSENT: CookieConsent = {
    required: true,
    webAnalysisGa: true,
    videoEmbedsVimeo: true,
    videoEmbedsYoutube: true,
    registrationToMagazine: true,
    externalAuthenticationGoogle: true,
    externalAuthenticationFb: true,
};
export const DECLINE_ALL_COOKIE_CONSENT: CookieConsent = {
    required: true,
    webAnalysisGa: false,
    videoEmbedsVimeo: false,
    videoEmbedsYoutube: false,
    registrationToMagazine: false,
    externalAuthenticationGoogle: false,
    externalAuthenticationFb: false,
};

const COOKIE_CONSTNT_CHANGE_HANDLERS: Map<CookieConsentKeys, ChangeHandler> = new Map<CookieConsentKeys, ChangeHandler>([
    [
        'webAnalysisGa',
        {
            onEnabled: gTagEnable,
            onDisabled: gTagDisable
        }
    ]
]);

const CookieConsentReactContext = createContext<{
    setCookieConsent: (cookieConsent: CookieConsent) => void;
    cookieConsent: CookieConsent | null;
    cookieConsentIsLoading: boolean;
}>({
    setCookieConsent: (cookieConsent: CookieConsent) => {/*empty*/ },
    cookieConsent: null,
    cookieConsentIsLoading: true,
});

const CookieConsentContext: FunctionComponent = ({ children }) => {
    const [cookieConsentCookie, setCookieConsentCookie, deleteCookieConsentCookie] = useCookie('cookieConsent');
    const [cookieConsentState, setCookieConsentState] = useState<{ value: CookieConsent | null } | null>(null);
    const clientConfig = useClientConfig();

    // to prevent different result from ssr
    useEffect(() => {
        const cookieValue: CookieConsentCookie | null = cookieConsentCookie && JSON.parse(cookieConsentCookie);
        if (cookieValue && cookieValue.version !== COOKIE_CONSENT_VERSION) {
            deleteCookieConsentCookie();
            setCookieConsentState({ value: null });
        } else {
            setCookieConsentState({ value: cookieValue?.value || null });
            if (cookieValue?.value) {
                handleCookieConsentChange(cookieValue?.value, null, clientConfig);
            }
        }

    }, []);

    const setCookieConsent = (value: CookieConsent) => {
        const oldValue = cookieConsentState;

        const cookieValue: CookieConsentCookie = { value, version: COOKIE_CONSENT_VERSION };
        setCookieConsentCookie(JSON.stringify(cookieValue), {
            expires: 365
        });

        handleCookieConsentChange(value, oldValue, clientConfig);

        setCookieConsentState({ value });
    };

    return (
        <CookieConsentReactContext.Provider value={{
            cookieConsent: cookieConsentState?.value || null,
            cookieConsentIsLoading: !cookieConsentState,
            setCookieConsent,
        }}>
            { children}
        </ CookieConsentReactContext.Provider>
    );
};

export const useCookieConsent = () => useContext(CookieConsentReactContext);
export default CookieConsentContext;

export interface CookieConsent {
    required: boolean;
    webAnalysisGa: boolean;
    videoEmbedsVimeo: boolean;
    videoEmbedsYoutube: boolean;
    registrationToMagazine: boolean;
    externalAuthenticationGoogle: boolean;
    externalAuthenticationFb: boolean;
}

interface CookieConsentCookie {
    value: CookieConsent;
    version: number;
}

interface ChangeHandler {
    onEnabled?: (clientConfig: ClientConfig) => void;
    onDisabled?: (clientConfig: ClientConfig) => void;
}

function handleCookieConsentChange(value: CookieConsent, oldValue: { value: CookieConsent | null; } | null, clientConfig: ClientConfig) {
    for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
            if (!oldValue || value[key] !== oldValue[key]) {
                const handler = COOKIE_CONSTNT_CHANGE_HANDLERS.get(key as CookieConsentKeys);
                if (handler) {
                    if (value[key]) {
                        handler.onEnabled?.(clientConfig);
                    } else {
                        handler.onDisabled?.(clientConfig);
                    }
                }
            }
        }
    }
}
