import React, { FunctionComponent } from 'react';
import MuiButton from '@material-ui/core/Button';
import StLink, { LinkProps } from '../StLink';
import { withStyles, createStyles } from '@material-ui/core/styles';
import theme from '../../theme/theme';
import Chevron from '../Chevron';
import { PrimaryColorTextMuiButton } from './PrimaryColorTextMuiButton';

export const SecondaryLinkButton: FunctionComponent<LinkButtonProps> = ({ children, ...props }) => (
    <MuiButton variant="outlined" color="primary" component={StLink} {...props}>
        {children}
    </MuiButton>
);

export const PrimaryLinkButton: FunctionComponent<LinkButtonProps> = ({ children, ...props }) => (
    <MuiButton variant="contained" color="primary" component={StLink} {...props}>
        {children}
    </MuiButton>
);

const InvButton: any = withStyles(createStyles({
    root: {
        backgroundColor: theme.color.background,
        color: theme.color.primary,
        '&:visited': {
            color: theme.color.primary
        }
    },
    containedPrimary: {
        '&::before': {
            borderBottom: `${theme.controls.buttonHeightPx}px solid ${theme.color.primary}`,
        },
        '&, a&,a&:visited': {
            color: theme.color.primary,
        },
        '&:hover,a&:hover,a&:active': {
            color: theme.color.background,
            backgroundColor: theme.color.background,
        },
    },
}))(MuiButton);

export const PrimaryInvLinkButton: FunctionComponent<LinkButtonProps> = (props) => (
    <InvButton variant="contained" color="primary" component={StLink} {...props}>
        {props.children}
    </InvButton>
);

const AltBackgroundButton: any = withStyles(createStyles({
    root: {
        backgroundColor: theme.color.backgroundAlt,
    },
    outlinedPrimary: {
        '&:hover,a&:hover,a&:active': {
            backgroundColor: theme.color.backgroundAlt,
        },
    },
    label: {
        justifyContent: 'space-between',
    }
}))(MuiButton);

const NavButton: any = withStyles(createStyles({
    root: {
        minWidth: 'auto',
    },
}))(AltBackgroundButton);

export const BackLinkButton: FunctionComponent<LinkButtonProps> = (props) => (
    <NavButton variant="outlined" color="primary" component={StLink} {...props} startIcon={<Chevron direction="left" />}>
        {props.children}
    </NavButton>
);
export const ForwardLinkButton: FunctionComponent<LinkProps> = props => (
    <NavButton variant="outlined" color="primary" component={StLink} {...props} endIcon={<Chevron direction="right" />}>
        {props.children}
    </NavButton>
);

export const TextLinkButton: FunctionComponent<LinkButtonProps> = (props) => (
    <PrimaryColorTextMuiButton variant="text" color="primary" component={StLink} {...props}>
        {props.children}
    </PrimaryColorTextMuiButton>
);

interface LinkButtonProps extends LinkProps {
    disabled?: boolean;
}