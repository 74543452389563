import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Header from '../Header/Header';
import { renderRoutes, RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import Footer from '../Footer';

const Root = styled.div`
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    min-height: 100vh;
`;

const Main = styled.div`
`;

const Layout: FunctionComponent<Props> = ({ route }) => (
    <Root>
        <Header />
        <Main>
            {renderRoutes(route?.routes)}
        </Main>
        <Footer />
    </Root>
);

interface Props extends RouteConfigComponentProps<any> {
}

export default Layout;
