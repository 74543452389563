import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Collapse from 'react-bootstrap/Collapse';
import { NavItemConfig } from '../../navItems';
import NavLink from './NavLink';
import Icon from '../Icon';
import media from '../../theme/media';
import Chevron from '../Chevron';
import SubNavItem from './SubNavItem';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router-dom';
import isNavItemActive from './isNavItemActive';

const SubMenu = styled.div`
    margin: 0;
    background: ${p => p.theme.color.background};
    &.collapsing {
        height: 0;
        overflow: hidden;
        transition: height .2s ease;
    }
    &.collapse:not(.show) {
        display: none; 
    }
    ${media.greaterThan('navMob')`
        position: absolute;
        right: -20px;    
    `}
`;

const SubMenuContent = styled.ul`
    margin: 0;
    ${media.greaterThan('navMob')`
        padding: 20px 20px 20px 10px;
    `}
`;

const NavLinkContent = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;
    transition: color 0.2s ease;
    white-space: nowrap;
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    
    &::after {
        display: block;
        position: absolute;
        content: '';
        height: 4px;
        background: ${p => p.theme.color.primary};
        bottom: 0;
        width: 100%;
        left: 0;
        transition: 0.2s;
        transform-origin: left;
        transform: scaleX(0)
    }
    
    ${media.greaterThan('navMob')`
        justify-content: flex-end;
    `}    
`;

const Root = styled.li`
    display: block;
    position: relative;
    line-height: 1.94rem;
    margin: 0;
    padding: 0;
    font-size: ${p => `${p.theme.font.size.menuMobRem}rem`};

    &.active  ${NavLinkContent},
    &:hover ${NavLinkContent}{
        color: ${p => p.theme.color.primary};
    }
    ${media.greaterThan('navMob')`
        font-size: ${p => `${p.theme.font.size.menuRem}rem`};
        line-height: calc(1em + 12px);
        &.active  ${NavLinkContent},
        &:hover ${NavLinkContent}{
            &::after {
                transform: scaleX(1)
            }
        }
    `}
`;

const ItemChevron = styled(Chevron)`
    width: 10px;
    height: 10px;
    margin-left: 6px;
    display: block;
    ${media.greaterThan('navMob')`
        display: none;
    `}
`;

const ItemIcon = styled(Icon)`
    margin-right: 10px;
`;

const ItemNavLink = styled(NavLink) <{ hasSeparator?: boolean }>`
    margin-left: ${p => p.hasSeparator ? '10px' : '0'};
`;

const NavItem: FunctionComponent<Props> = (props) => {
    const isActive = isNavItemActive(props.item, props.location.pathname);
    return (
        <Root onMouseEnter={() => props.onMouseEnter && props.onMouseEnter(props.item)}
            onMouseLeave={() => props.onMouseLeave && props.onMouseLeave(props.item)}
            onClick={() => props.onClick && props.onClick(props.item)} className={`${isActive ? 'active' : ''}`}>
            <ItemNavLink item={props.item} inheritColor={true} hasSeparator={props.item.hasSeparator}>
                <NavLinkContent>
                    {props.item.icon && <ItemIcon {...props.item.icon} />}
                    {props.item.title}
                    {props.item.subItems && <ItemChevron direction={props.isExpanded ? 'down' : 'right'} />}
                </NavLinkContent>
            </ItemNavLink>
            {props.item.subItems && <Collapse in={props.isExpanded}>
                <SubMenu>
                    <SubMenuContent>
                        {props.item.subItems.map(subItem => <SubNavItem key={subItem.title} item={subItem} />)}
                    </SubMenuContent>
                </SubMenu>
            </Collapse>}
        </Root>
    );
};

interface Props extends RouteComponentProps {
    item: NavItemConfig;
    isExpanded: boolean;
    onMouseEnter?: (item: NavItemConfig) => void;
    onMouseLeave?: (item: NavItemConfig) => void;
    onClick?: (item: NavItemConfig) => void;
}

export default withRouter(NavItem);