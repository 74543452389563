import React, { FunctionComponent } from 'react';
import { useFormikContext } from 'formik';
import { PrimaryButton, SecondaryButton, TextButton } from '../Button/Button';

const formButton = (WrappedComponent: React.ComponentType<Props>): FunctionComponent<Props> =>
    ({ type, children, disabled }) => {
        const formik = useFormikContext();
        return (
            <WrappedComponent type={type} disabled={(formik ? formik.isSubmitting : false) || disabled}>
                {children}
            </WrappedComponent>
        );
    };

const formPrimaryButton = (WrappedComponent: React.ComponentType<FormPrimaryButtonProps>): FunctionComponent<FormPrimaryButtonProps> =>
    ({ type, children, disabled, important }) => {
        const formik = useFormikContext();
        return (
            <WrappedComponent type={type} disabled={(formik ? formik.isSubmitting : false) || disabled} important={important}>
                {children}
            </WrappedComponent>
        );
    };

export const FormPrimaryButton = formPrimaryButton(PrimaryButton);
export const FormSecondaryButton = formButton(SecondaryButton);
export const FormTextButton = formButton(TextButton);

interface Props {
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
}

interface FormPrimaryButtonProps extends Props {
    important?: boolean;
}
