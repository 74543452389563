import wretch, { Wretcher } from 'wretch';

class WebProxyClient {
    client: Wretcher;
    constructor(baseUrl: string, options?: Options) {
        this.client = wretch(baseUrl)
            .headers({ 'X-Requested-With': 'XMLHttpRequest' })
            .errorType('text');

        if (options?.csrfToken) {
            this.client = this.client.headers({ 'RequestVerificationToken': options.csrfToken });
        }
    }

    post<TResp extends {}>(url: string, data?: any, options?: ReqOptions) {
        return this.createBaseReq(url, options)
            .post(data)
            .text((text) => (text.length ? JSON.parse(text) : {}) as TResp)
            .catch(err => {
                try {
                    err.data = JSON.parse(err.text);
                } catch (err) {
                    // it is not a json, so we are not going to populate data and we are going to rethrow the original error
                }
                throw err;
            });
    }

    get<TResp extends {}>(url: string, queryParams?: any, options?: ReqOptions) {
        return this.createBaseReq(url, options)
            .query(queryParams)
            .get()
            .json((result) => (result || {}) as TResp)
            .catch(err => {
                try {
                    err.data = JSON.parse(err.text);
                } catch (err) {
                    // it is not a json, so we are not going to populate data and we are going to rethrow the original error
                }
                throw err;
            });
    }

    private createBaseReq(url: string, options?: ReqOptions): Wretcher {
        let req = this.client
            .url(url);
        if (options?.captcha) {
            req = req.headers({ ...req.headers, captcha: options.captcha });
        }
        return req;
    }
}

export default WebProxyClient;

interface Options {
    csrfToken?: string;
}

interface ReqOptions {
    captcha?: string;
}