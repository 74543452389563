import React, { useEffect, useState, FunctionComponent, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TopBarProgress from './TopBarProgress';
import Seo from './Seo';
import Error, { ErrorProps } from './Error';
import * as H from 'history';
import GlobalNotificationDialogPopup from './GlobalNotificationDialogPopup';
import CookieDialogPopup from './Cookie/CookieDialogPopup';
import { useGtag } from '../utils/gtag';
import { useCookieConsent } from './Cookie/CookieConsentContext';

const page = (WrappedComponent: React.ComponentType<PageProps>, options?: Options): FunctionComponent<Props> =>
    (props) => {

        const history = useHistory();

        const location = useLocation();
        let initData: PageData | null = null;
        if (props.staticContext) { // render on server
            initData = props.staticContext.data;
        } else if ((window as any).__ROUTE_DATA__) { // first render on client
            initData = (window as any).__ROUTE_DATA__;
            delete (window as any).__ROUTE_DATA__;
        }

        if (options?.isStatic) {
            initData = {
                model: null,
                statusCode: 200,
            };
        }

        if (!props.staticContext) {
            const { cookieConsent } = useCookieConsent();
            useGtag(cookieConsent);

            useEffect(() => {
                let canceled = false;

                if (!initData) { // not a first render after SSR
                    preloadComponentChunk(WrappedComponent);

                    let clientGetData: undefined | boolean | ClientGetDataFun = (WrappedComponent as any).clientGetData;

                    if (clientGetData === false) {
                        clientGetData = (_: ClientGetDataProps) => Promise.resolve(null);
                    }

                    if (clientGetData) {
                        (clientGetData as ClientGetDataFun)({ location })
                            .then(data => {
                                if (!canceled) {
                                    setPageData({ model: data });
                                }
                            })
                            .catch((err) => {
                                if (!canceled) {
                                    setPageData({
                                        model: {},
                                        statusCode: err.status || 500,
                                        error: err
                                    });
                                }
                            });
                    } else {
                        const url = `/_pagedata${location.pathname}${location.search}`;
                        fetch(url, { headers: { accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest' } })
                            .then(r => {
                                if (!r.ok) {
                                    if (r.status === 401) {
                                        canceled = true;
                                        history.push(`/konto/anmelden?returnUrl=${encodeURIComponent(url)}`);
                                    }
                                    return r.json()
                                        .catch(() => {
                                            return {
                                                statusCode: r.status,
                                            };
                                        });
                                }
                                return r.json();
                            })
                            .then(data => {
                                if (!canceled) { // moved to another route before finishing fetch (based on https://www.robinwieruch.de/react-hooks-fetch-data)
                                    setPageData(data);
                                }
                            });
                    }
                }
                return () => { canceled = true; };

            }, [location.pathname]); // we are ignoring query string on purpose - we are using it for filter and we don't want to have full page refresh in that case
        }

        const [pageData, setPageData] = useState(initData);

        const ErrorComponent = pageData?.statusCode === 404 && options?.customNotFoundError || Error;

        // <Seo> - handle missing seo in a specific page - use default properties
        return (
            <>
                {pageData &&
                    ((pageData.statusCode || 0) < 400 && <>
                        <Seo />
                        <WrappedComponent model={pageData.model} />
                        {!options?.disablePopups && <>
                            <GlobalNotificationDialogPopup />
                            <CookieDialogPopup /></>
                        }
                    </> || <ErrorComponent model={pageData.model} statusCode={pageData.statusCode || 500} />)
                    || <TopBarProgress />
                }
            </>
        );
    };

export interface Props {
    staticContext?: any;
}
export interface PageProps<T = any> {
    model: T;
}

export interface ClientGetDataProps {
    location: H.Location;
}

interface PageData {
    model: any;
    statusCode?: number;
    error?: any;
}

interface Options {
    customNotFoundError?: React.ComponentType<ErrorProps>;
    isStatic?: boolean;
    disablePopups?: boolean;
}

type ClientGetDataFun = ((p: ClientGetDataProps) => Promise<any>);

export default page;

function preloadComponentChunk(WrappedComponent: any) {
    if (WrappedComponent.preload) {
        WrappedComponent.preload();
    }
}