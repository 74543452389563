// based on Gatsby.js

import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import siteConfig from '../site.config';
import ClientConfigContext, { ClientConfig } from '../ClientConfig';

const Seo: FunctionComponent<SeoProps> = ({ description, title, image }) => {
  const lang = siteConfig.lang;
  description = description || siteConfig.description;

  const getMeta = (clientConfig: ClientConfig) => {
    image = (new URL(image || siteConfig.image, clientConfig.baseUrl)).toString(); // this also works for absolute urls - see https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
    let meta = [
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      // {
      //   property: `og:url`,// TODO !!
      // },
      {
        name: `twitter:card`,
        content: `${image ? 'summary_large_image' : 'summary'}`,
      },
      {
        name: `twitter:creator`,
        content: siteConfig.twitterCreator,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      }
    ];

    if (image) {
      meta = [...meta, ...[{
        property: `og:image`,
        content: image,
      }, {
        name: `twitter:image`,
        content: image,
      }]];
    }
    return meta;
  };

  return (
    <ClientConfigContext.Consumer>
      {clientConfig =>
        <Helmet
          htmlAttributes={{ lang }}
          title={title}
          titleTemplate={`%s | ${siteConfig.title}`}
          defaultTitle={siteConfig.title}
          meta={getMeta(clientConfig)}
        />
      }
    </ClientConfigContext.Consumer>
  );
};

export interface SeoProps {
  description?: string;
  title?: string;
  image?: string;
  imageFocalPoint?: { x: number; y: number; };
}

export default Seo;
