import React, { FunctionComponent } from 'react';
import MuiButton from '@material-ui/core/Button';
import { PrimaryColorTextMuiButton, PrimaryColorTextMuiButtonHeight } from './PrimaryColorTextMuiButton';
import { withStyles, createStyles } from '@material-ui/core/styles';

const StyledPrimaryMuiButton: any = withStyles(createStyles<string, { important: string | undefined }>({
    root: {
        height: props => props.important ? '49px' : undefined
    },
    containedPrimary: {
        '&::before': {
            height: props => props.important ? '49px' : undefined,
            borderBottomWidth: props => props.important ? '49px' : undefined
        },
    },
}))(MuiButton);

export const PrimaryButton: FunctionComponent<PrimaryButtonProps> = ({ children, onClick, className, disabled, type, important }) => (
    <StyledPrimaryMuiButton variant="contained" className={className} color="primary" onClick={onClick} disabled={disabled} type={type} important={important?.toString()}>
        {children}
    </StyledPrimaryMuiButton>
);

export const SecondaryButton: FunctionComponent<ButtonProps> = ({ children, onClick, className, disabled, type }) => (
    <MuiButton variant="outlined" className={className} color="primary" onClick={onClick} disabled={disabled} type={type}>
        {children}
    </MuiButton>
);

export const TextButton: FunctionComponent<TextButtonProps> = ({ children, onClick, className, disabled, type, height }) => (
    <PrimaryColorTextMuiButton variant="text" color="primary" className={className} onClick={onClick} disabled={disabled} type={type} height={height}>
        {children}
    </PrimaryColorTextMuiButton>
);

export interface ButtonProps {
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
}

export interface PrimaryButtonProps extends ButtonProps {
    important?: boolean;
}

export interface TextButtonProps extends ButtonProps {
    height?: PrimaryColorTextMuiButtonHeight;
}
