import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import chevronSvg from '../assets/sprite/chevron.svg';
import chevronOutlinedSvg from '../assets/sprite/chevron-outline.svg';
import chevronBoldSvg from '../assets/sprite/chevron-outline-bold.svg';
import Icon, { IconProps } from './Icon';

const rotations = {
    right: 'none',
    down: 'rotate(90deg)',
    left: 'rotate(180deg)',
    up: 'rotate(270deg)',
};
const Root = styled(Icon) <IconProps & Props>`
    transition: transform .2s ease-out;
    transform: ${p => rotations[p.direction]};
`;

const Chevron: FunctionComponent<Props> = (props) => (
    <Root glyph={props.type === 'filled' && chevronSvg || props.type === 'outlined-bold' && chevronBoldSvg || chevronOutlinedSvg} isPath {...props} />
);

interface Props {
    direction: 'right' | 'left' | 'up' | 'down';
    type?: 'outlined' | 'filled' | 'outlined-bold';
    className?: string;
    size?: string;
}

export default Chevron;