import { IconGlyph } from './components/Icon';
import lockSvg from './assets/sprite/lock.svg';
import userSvg from './assets/sprite/user.svg';
import externalLinkSvg from './assets/sprite/external-link.svg';
import logOutSvg from './assets/sprite/log-out.svg';
import { CurrentUserViewModel } from './utils/accountWebProxy';

export interface NavItemConfig {
    title: string;
    path?: string;
    external?: boolean;
    externalTarget?: '_self' | '_blank';
    subItems?: NavItemConfig[];
    icon?: {
        isPath?: boolean,
        glyph: IconGlyph
    };
    hasSeparator?: boolean;
    // Applied only for root items - TODO: add map to getNavItems if required also for child items
    condition?: ({ user }: { user: CurrentUserViewModel | null | undefined }) => boolean | null | undefined;
}

const navItems: NavItemConfig[] = [
    {
        title: 'Immobiliensuche',
        path: '/immobiliensuche'
    },
    {
        title: 'Projekte',
        path: '/projekte',
    },
    {
        title: 'Hausverwaltung',
        subItems: [
            { title: 'Meine Liegenschaft', path: '/hausverwaltung/meine-liegenschaft' },
            { title: 'Bewohnerportal', path: '/hausverwaltung/bewohnerportal' },
            { title: 'Downloads & FAQs', path: '/hausverwaltung/downloads-faqs' },
        ]
    },
    {
        title: 'Unternehmen',
        subItems: [
            { title: 'Über uns', path: '/unternehmen/ueber-uns' },
            { title: 'News', path: '/unternehmen/news' },
            { title: 'Magazin', path: '/unternehmen/magazin' },
            { title: 'Blog', path: '/unternehmen/blog' },
            { title: 'Genossenschaftsstruktur', path: '/unternehmen/genossenschaftsstruktur' },
            { title: 'Team', path: '/unternehmen/team' },
            { title: 'Jobs', path: '/jobs' },
            { title: 'Beteiligungen', path: '/unternehmen/beteiligungen' },
            { title: 'Generalversammlung', path: '/unternehmen/generalversammlung' },
            { title: 'Satzung', path: '/unternehmen/satzung' },
        ]
    },
    {
        title: 'Referenzen',
        path: '/referenzen',
    },
    {
        title: 'kontakt',
        path: '/kontakt',
    },
    {
        title: 'konto',
        icon: { glyph: userSvg },
        hasSeparator: true,
        condition: ({ user }) => user && user.isAuthenticated,
        subItems: [
            {
                title: 'Wohnungsanfragen',
            },
            {
                title: 'Meine Anfragen',
                path: '/meine-anfragen',
            },
            {
                title: 'Einstellungen',
                path: '/konto/kontoeinstellungen',
            },
            {
                title: 'Abmelden',
                path: '/konto/abmelden',
                external: true,
                externalTarget: '_self',
                icon: { glyph: logOutSvg, isPath: true },
            },
            {
                hasSeparator: true,
                title: 'Bewohnerportal',
                path: 'https://portal.frieden.at',
                external: true,
                icon: { glyph: externalLinkSvg, isPath: true },
            },
            {
                title: 'Professionistenportal',
                path: 'https://professionistenportal.frieden.at',
                external: true,
                icon: { glyph: externalLinkSvg, isPath: true },
            },
        ]
    },
    {
        title: 'login',
        icon: { glyph: lockSvg },
        hasSeparator: true,
        condition: ({ user }) => !user || !user.isAuthenticated,
        subItems: [
            {
                title: 'Wohnungsanfragen',
                path: '/konto/anmelden',
            },
            {
                title: 'Bewohnerportal',
                path: 'https://portal.frieden.at',
                external: true,
                icon: { glyph: externalLinkSvg, isPath: true },
            },
            {
                title: 'Professionistenportal',
                path: 'https://professionistenportal.frieden.at',
                external: true,
                icon: { glyph: externalLinkSvg, isPath: true },
            },
        ]
    }
];

const getNavItems = ({ user }: { user: CurrentUserViewModel | null | undefined }) => navItems.filter(n => !n.condition || n.condition({ user }));
export default getNavItems;