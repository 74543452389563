import { RouteConfig } from 'react-router-config';
import { matchPath } from 'react-router-dom';

/**
 * returns flat array of routes that matches the given predicate
 * @param routes 
 * @param predicate 
 */
const findRoute = (routes: RouteConfig[], path: string): RouteConfig | null => {
    for (const route of routes) {
        if (matchPath(path, {
            path: route.path,
            exact: true,
        })) {
            return route;
        }

        if (route.routes) {
            return findRoute(route.routes, path);
        }
    }
    return null;
};

export default findRoute;
