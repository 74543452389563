
import React, { useEffect, useState, FunctionComponent, useRef } from 'react';
import TopBarProgressIndicator from 'react-topbar-progress-indicator';
import theme from '../theme/theme';

TopBarProgressIndicator.config({
    barColors: {
        '0': theme.color.topProgress,
        '1.0': theme.color.topProgress
    },
    barThickness: 2,
    shadowBlur: 0
});

const TopBarProgress = TopBarProgressIndicator as any;

export default TopBarProgress;