import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Container from './layout/Container';
import logo from '../assets/Logo.svg';
import LogoNof from '../assets/LogoNOF.svg';
import LogoTfw from '../assets/LogoTFW.svg';
import media from '../theme/media';
import StLink from './StLink';
import { useGlobalData } from '../GlobalData';

const Root = styled.footer`
    display: grid;
    grid: 
        "links logos" max-content
        "copy copy" max-content
        / max-content max-content;
    row-gap: 25px;
    padding: 33px 0px 39px;
    margin-top: 75px;
    justify-content: space-between;
    ${media.greaterThan('footerMob')`
        justify-content: center;
        grid: 
            "logos" max-content
            "links" max-content
            "copy" max-content
            / max-content;
    `}      
    ${media.greaterThan('footerMedium')`
        justify-content: stretch;
        align-items: center;
        grid: 
            "logos . links . copy" 1fr
            / max-content minmax(10px, 1fr) auto minmax(10px, 55px) max-content;
    `}  
`;

const Logos = styled.div`
    display: grid;
    grid-auto-columns: max-content;
    grid-area: logos;
    row-gap: 37px;
    justify-items: center;
    img{
        height: 83px;
    };
    ${media.greaterThan('footerMob')`
        justify-content: center;
        grid-auto-flow: column;
        column-gap: 24px;
    `}      
`;
const Links = styled.div`
    display: grid;
    grid-auto-columns: max-content;
    justify-content: space-between;
    grid-area: links;
    row-gap: 30px;
    ${media.greaterThan('footerMob')`
        grid-auto-flow: column;
        justify-content: center;
        column-gap: 36px;
    `}       
    ${media.greaterThan('footerMedium')`
        column-gap: 35px;
        justify-content: space-between;
    `}
`;
const Copy = styled.div`
    grid-auto-flow: column;
    grid-area: copy;
    text-align: center;
`;

const Footer: FunctionComponent = () => {
    const hasJobs = !!useGlobalData().jobs?.count;
    const now = new Date();

    return (
        <Container>
            <Root>
                <Logos>
                    <img src={logo} alt="logo FR" />
                    <img src={LogoNof} alt="logo NÖF" />
                    <img src={LogoTfw} alt="logo TFW" />
                </Logos>
                <Links>
                    {hasJobs && <StLink to="/jobs">Jobs</StLink>}
                    <StLink to="/unternehmen/news">News</StLink>
                    <StLink to="/hausverwaltung/downloads-faqs">FAQs</StLink>
                    <StLink to="/impressum">Impressum</StLink>
                    <StLink to="https://whistleblowersoftware.com/secure/frieden" externalTarget="_blank" external={true} follow={false}>Hinweisgebersystem</StLink>
                    <StLink to="/datenschutz">Datenschutz</StLink>
                </Links>
                <Copy>
                    <div>Frieden © 2021 - {now.getFullYear()}</div>
                </Copy>
            </Root>
        </Container>
    );
};

export default Footer;