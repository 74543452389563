import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const PlayButton = styled.div<PlayButtonProps>`
    position: absolute;
    cursor: pointer;
    top: ${p => p.position?.top ?? 30}%;
    left: ${p => p.position?.left ?? 50}%;
    margin-top: -40px;
    margin-left: -40px;
    background-color: #fff;
    border-radius: 50%;
    border: 5px solid ${p => p.theme.color.primary};
    width: 80px;
    height: 80px;
`;

const PlayIcon = styled.div`
    position: absolute;
    transform-origin: center;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -15px;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    background-color: ${p => p.theme.color.primary};
    width: 40px;
    height: 40px;
`;

const PlayButtonAbsolute: FunctionComponent<PlayButtonProps> = ({ onClick, onHover, position }) => {
    return (
        <PlayButton onClick={() => onClick()} onMouseEnter={() => { if (onHover) { onHover(true); } }} onMouseLeave={() => { if (onHover) { onHover(false); } }} position={position}>
            <PlayIcon />
        </PlayButton>
    );
};

export default PlayButtonAbsolute;

interface PlayButtonProps {
    onClick: () => void;
    onHover?: (hover: boolean) => void;
    position?: { top: number, left: number };
}