import { string as yupString, LocaleObject } from 'yup';

export const passwordSchema = ({ ignorePolicy }: { ignorePolicy?: boolean } = {}) => {
    let schema = yupString().required('Das Kennwort muss eingeben werden.');
    if (!ignorePolicy) {
        schema = schema.min(8, 'Das Kennwort muss mindestens 8 Zeichen lang sein.');
    }
    return schema;
};

export const defaultValidationMessages: LocaleObject = {
  string: {
    email: 'Empfänger E-Mail bitte im gültigen Format eingeben.',
  },
  mixed: {
    required: 'Dieses Feld darf nicht leer sein.',
  },
};
