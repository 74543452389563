////////////////////////////////////////////////////////////////////////////////////////////////
// This file is generated by ClientRouteGenerator.cs on server start while in dev mode based on routes.hbs
////////////////////////////////////////////////////////////////////////////////////////////////

import { RouteConfig } from 'react-router-config';
import Layout from './components/layout/Layout';
import page from './components/Page';
import { ErrorNotFound } from './components/Error';
import loadable from '@loadable/component';

const AccountRegister = loadable(() => import('./pages/Account/Register'));
const AccountRegisterEmailSent = loadable(() => import('./pages/Account/RegisterEmailSent'));
const AccountRegisterInvalidLink = loadable(() => import('./pages/Account/RegisterInvalidLink'));
const AccountRegisterFinish = loadable(() => import('./pages/Account/RegisterFinish'));
const AccountLogin = loadable(() => import('./pages/Account/Login'));
const AccountResetPassword = loadable(() => import('./pages/Account/ResetPassword'));
const AccountResetPasswordEmailSent = loadable(() => import('./pages/Account/ResetPasswordEmailSent'));
const AccountResetPasswordFinish = loadable(() => import('./pages/Account/ResetPasswordFinish'));
const AccountResetPasswordInvalidLink = loadable(() => import('./pages/Account/ResetPasswordInvalidLink'));
const AccountResetPasswordSuccess = loadable(() => import('./pages/Account/ResetPasswordSuccess'));
const AccountSettings = loadable(() => import('./pages/Account/Settings'));
const AccountChangeEmail = loadable(() => import('./pages/Account/ChangeEmail'));
const AccountChangeEmailInvalidLink = loadable(() => import('./pages/Account/ChangeEmailInvalidLink'));
const AccountChangeEmailFinish = loadable(() => import('./pages/Account/ChangeEmailFinish'));
const AccountChangePassword = loadable(() => import('./pages/Account/ChangePassword'));
const AccountDeleteAccountEmailSent = loadable(() => import('./pages/Account/DeleteAccountEmailSent'));
const AccountDeleteAccountFinish = loadable(() => import('./pages/Account/DeleteAccountFinish'));
const AccountDeleteAccountInvalidLink = loadable(() => import('./pages/Account/DeleteAccountInvalidLink'));
const AccountDeleteAccountSuccess = loadable(() => import('./pages/Account/DeleteAccountSuccess'));
const AccountAccountExternalLinked = loadable(() => import('./pages/Account/AccountExternalLinked'));
const CompanyParticipants = loadable(() => import('./pages/Company/Participants'));
const CompanyStructure = loadable(() => import('./pages/Company/Structure'));
const CompanyAboutUs = loadable(() => import('./pages/Company/AboutUs'));
const CompanyHistory = loadable(() => import('./pages/Company/History'));
const CompanyGeneralAssembly = loadable(() => import('./pages/Company/GeneralAssembly'));
const CompanyStatutes = loadable(() => import('./pages/Company/Statutes'));
const CompanyTeam = loadable(() => import('./pages/Company/Team'));
const CompanyMagazin = loadable(() => import('./pages/Company/Magazin'));
const ContactIndex = loadable(() => import('./pages/Contact/Index'));
const HomeIndex = loadable(() => import('./pages/Home/Index'));
const HomeMyInquiries = loadable(() => import('./pages/Home/MyInquiries'));
const HomeError = loadable(() => import('./pages/Home/Error'));
const HomeImpressum = loadable(() => import('./pages/Home/Impressum'));
const HomeDatenschutz = loadable(() => import('./pages/Home/Datenschutz'));
const JobsIndex = loadable(() => import('./pages/Jobs/Index'));
const JobsDetail = loadable(() => import('./pages/Jobs/Detail'));
const PostsIndex = loadable(() => import('./pages/Posts/Index'));
const PostsDetail = loadable(() => import('./pages/Posts/Detail'));
const PropertyManagementIndex = loadable(() => import('./pages/PropertyManagement/Index'));
const PropertyManagementBewohnerportal = loadable(() => import('./pages/PropertyManagement/Bewohnerportal'));
const PropertyManagementDownloadsFAQs = loadable(() => import('./pages/PropertyManagement/DownloadsFAQs'));
const ReferencesIndex = loadable(() => import('./pages/References/Index'));
const ReferencesDetail1 = loadable(() => import('./pages/References/Detail1'));
const ReferencesDetail2 = loadable(() => import('./pages/References/Detail2'));
const ReferencesDetail3 = loadable(() => import('./pages/References/Detail3'));
const SalesProjectDetail = loadable(() => import('./pages/SalesProject/Detail'));
const SalesProjectDetailError404 = loadable(() => import('./pages/SalesProject/DetailError404'));
const SalesProjectIndex = loadable(() => import('./pages/SalesProject/Index'));
const SalesUnitDetail = loadable(() => import('./pages/SalesUnit/Detail'));
const SalesUnitDetailError404 = loadable(() => import('./pages/SalesUnit/DetailError404'));
const SalesUnitIndex = loadable(() => import('./pages/SalesUnit/Index'));
const VideosIndex = loadable(() => import('./pages/Videos/Index'));

const routes: RouteConfig[] = [
    {
        component: Layout,
        routes: [
            {
                path: '/konto/registrieren',
                exact: true,
                component: page(AccountRegister, {
                    isStatic: true,
                }),
                preload: () => AccountRegister.preload(),            
            },
            {
                path: '/konto/registrieren/verifizierung',
                exact: true,
                component: page(AccountRegisterEmailSent, {
                    isStatic: true,
                }),
                preload: () => AccountRegisterEmailSent.preload(),            
            },
            {
                path: '/konto/registrieren/link-ungueltig',
                exact: true,
                component: page(AccountRegisterInvalidLink, {
                    isStatic: true,
                }),
                preload: () => AccountRegisterInvalidLink.preload(),            
            },
            {
                path: '/konto/registrieren/abschluss',
                exact: true,
                component: page(AccountRegisterFinish, {
                }),
                preload: () => AccountRegisterFinish.preload(),            
            },
            {
                path: '/konto/anmelden',
                exact: true,
                component: page(AccountLogin, {
                    isStatic: true,
                }),
                preload: () => AccountLogin.preload(),            
            },
            {
                path: '/konto/kennwort-zuruecksetzen',
                exact: true,
                component: page(AccountResetPassword, {
                }),
                preload: () => AccountResetPassword.preload(),            
            },
            {
                path: '/konto/kennwort-zuruecksetzen/verifizierung',
                exact: true,
                component: page(AccountResetPasswordEmailSent, {
                }),
                preload: () => AccountResetPasswordEmailSent.preload(),            
            },
            {
                path: '/konto/kennwort-zuruecksetzen/abschluss',
                exact: true,
                component: page(AccountResetPasswordFinish, {
                }),
                preload: () => AccountResetPasswordFinish.preload(),            
            },
            {
                path: '/konto/kennwort-zuruecksetzen/link-ungueltig',
                exact: true,
                component: page(AccountResetPasswordInvalidLink, {
                }),
                preload: () => AccountResetPasswordInvalidLink.preload(),            
            },
            {
                path: '/konto/kennwort-zuruecksetzen/abgechlossen',
                exact: true,
                component: page(AccountResetPasswordSuccess, {
                }),
                preload: () => AccountResetPasswordSuccess.preload(),            
            },
            {
                path: '/konto/kontoeinstellungen',
                exact: true,
                component: page(AccountSettings, {
                }),
                preload: () => AccountSettings.preload(),            
            },
            {
                path: '/konto/email-aendern',
                exact: true,
                component: page(AccountChangeEmail, {
                }),
                preload: () => AccountChangeEmail.preload(),            
            },
            {
                path: '/konto/email-aendern/link-ungueltig',
                exact: true,
                component: page(AccountChangeEmailInvalidLink, {
                }),
                preload: () => AccountChangeEmailInvalidLink.preload(),            
            },
            {
                path: '/konto/email-aendern/abschluss',
                exact: true,
                component: page(AccountChangeEmailFinish, {
                }),
                preload: () => AccountChangeEmailFinish.preload(),            
            },
            {
                path: '/konto/kennwort-aendern',
                exact: true,
                component: page(AccountChangePassword, {
                }),
                preload: () => AccountChangePassword.preload(),            
            },
            {
                path: '/konto/konto-loeschenn/verifizierung',
                exact: true,
                component: page(AccountDeleteAccountEmailSent, {
                }),
                preload: () => AccountDeleteAccountEmailSent.preload(),            
            },
            {
                path: '/konto/konto-loeschen/abschluss',
                exact: true,
                component: page(AccountDeleteAccountFinish, {
                }),
                preload: () => AccountDeleteAccountFinish.preload(),            
            },
            {
                path: '/konto/konto-loeschen/link-ungueltig',
                exact: true,
                component: page(AccountDeleteAccountInvalidLink, {
                }),
                preload: () => AccountDeleteAccountInvalidLink.preload(),            
            },
            {
                path: '/konto/konto-loeschen/abgechlossen',
                exact: true,
                component: page(AccountDeleteAccountSuccess, {
                }),
                preload: () => AccountDeleteAccountSuccess.preload(),            
            },
            {
                path: '/konto/konto-verknupft',
                exact: true,
                component: page(AccountAccountExternalLinked, {
                }),
                preload: () => AccountAccountExternalLinked.preload(),            
            },
            {
                path: '/unternehmen/beteiligungen',
                exact: true,
                component: page(CompanyParticipants, {
                    isStatic: true,
                }),
                preload: () => CompanyParticipants.preload(),            
            },
            {
                path: '/unternehmen/genossenschaftsstruktur',
                exact: true,
                component: page(CompanyStructure, {
                    isStatic: true,
                }),
                preload: () => CompanyStructure.preload(),            
            },
            {
                path: '/unternehmen/ueber-uns',
                exact: true,
                component: page(CompanyAboutUs, {
                    isStatic: true,
                }),
                preload: () => CompanyAboutUs.preload(),            
            },
            {
                path: '/unternehmen/ueber-uns/historische-zeittafel',
                exact: true,
                component: page(CompanyHistory, {
                    isStatic: true,
                }),
                preload: () => CompanyHistory.preload(),            
            },
            {
                path: '/unternehmen/generalversammlung',
                exact: true,
                component: page(CompanyGeneralAssembly, {
                }),
                preload: () => CompanyGeneralAssembly.preload(),            
            },
            {
                path: '/unternehmen/satzung',
                exact: true,
                component: page(CompanyStatutes, {
                    isStatic: true,
                }),
                preload: () => CompanyStatutes.preload(),            
            },
            {
                path: '/unternehmen/team',
                exact: true,
                component: page(CompanyTeam, {
                    isStatic: true,
                }),
                preload: () => CompanyTeam.preload(),            
            },
            {
                path: '/unternehmen/magazin',
                exact: true,
                component: page(CompanyMagazin, {
                    isStatic: true,
                }),
                preload: () => CompanyMagazin.preload(),            
            },
            {
                path: '/kontakt',
                exact: true,
                component: page(ContactIndex, {
                    isStatic: true,
                }),
                preload: () => ContactIndex.preload(),            
            },
            {
                path: '/',
                exact: true,
                component: page(HomeIndex, {
                }),
                preload: () => HomeIndex.preload(),            
            },
            {
                path: '/meine-anfragen',
                exact: true,
                component: page(HomeMyInquiries, {
                }),
                preload: () => HomeMyInquiries.preload(),            
            },
            {
                path: '/Fehler',
                exact: true,
                component: page(HomeError, {
                }),
                preload: () => HomeError.preload(),            
            },
            {
                path: '/impressum',
                exact: true,
                component: page(HomeImpressum, {
                    isStatic: true,
                }),
                preload: () => HomeImpressum.preload(),            
            },
            {
                path: '/datenschutz',
                exact: true,
                component: page(HomeDatenschutz, {
                    isStatic: true,
                    disablePopups: true,
                }),
                preload: () => HomeDatenschutz.preload(),            
            },
            {
                path: '/jobs',
                exact: true,
                component: page(JobsIndex, {
                }),
                preload: () => JobsIndex.preload(),            
            },
            {
                path: '/jobs/:alias',
                exact: true,
                component: page(JobsDetail, {
                }),
                preload: () => JobsDetail.preload(),            
            },
            {
                path: '/unternehmen/news',
                exact: true,
                component: page(PostsIndex, {
                }),
                preload: () => PostsIndex.preload(),            
            },
            {
                path: '/unternehmen/blog',
                exact: true,
                component: page(PostsIndex, {
                }),
                preload: () => PostsIndex.preload(),            
            },
            {
                path: '/unternehmen/news/:alias',
                exact: true,
                component: page(PostsDetail, {
                }),
                preload: () => PostsDetail.preload(),            
            },
            {
                path: '/unternehmen/blog/:alias',
                exact: true,
                component: page(PostsDetail, {
                }),
                preload: () => PostsDetail.preload(),            
            },
            {
                path: '/hausverwaltung/meine-liegenschaft',
                exact: true,
                component: page(PropertyManagementIndex, {
                }),
                preload: () => PropertyManagementIndex.preload(),            
            },
            {
                path: '/hausverwaltung/bewohnerportal',
                exact: true,
                component: page(PropertyManagementBewohnerportal, {
                    isStatic: true,
                }),
                preload: () => PropertyManagementBewohnerportal.preload(),            
            },
            {
                path: '/hausverwaltung/downloads-faqs',
                exact: true,
                component: page(PropertyManagementDownloadsFAQs, {
                    isStatic: true,
                }),
                preload: () => PropertyManagementDownloadsFAQs.preload(),            
            },
            {
                path: '/referenzen',
                exact: true,
                component: page(ReferencesIndex, {
                }),
                preload: () => ReferencesIndex.preload(),            
            },
            {
                path: '/referenzen/gemeinlebarn',
                exact: true,
                component: page(ReferencesDetail1, {
                }),
                preload: () => ReferencesDetail1.preload(),            
            },
            {
                path: '/referenzen/radfeld',
                exact: true,
                component: page(ReferencesDetail2, {
                }),
                preload: () => ReferencesDetail2.preload(),            
            },
            {
                path: '/referenzen/lienz',
                exact: true,
                component: page(ReferencesDetail3, {
                }),
                preload: () => ReferencesDetail3.preload(),            
            },
            {
                path: '/projekte/:aliasOrId',
                exact: true,
                component: page(SalesProjectDetail, {
                    customNotFoundError: SalesProjectDetailError404,
                }),
                preload: () => SalesProjectDetail.preload(),            
            },
            {
                path: '/projekte',
                exact: true,
                component: page(SalesProjectIndex, {
                }),
                preload: () => SalesProjectIndex.preload(),            
            },
            {
                path: '/immobiliensuche/:id',
                exact: true,
                component: page(SalesUnitDetail, {
                    customNotFoundError: SalesUnitDetailError404,
                }),
                preload: () => SalesUnitDetail.preload(),            
            },
            {
                path: '/immobiliensuche',
                exact: true,
                component: page(SalesUnitIndex, {
                }),
                preload: () => SalesUnitIndex.preload(),            
            },
            {
                path: '/videos/:alias',
                exact: true,
                component: page(VideosIndex, {
                }),
                preload: () => VideosIndex.preload(),            
            },
            {
                component: page(ErrorNotFound)
            },            
        ]
    }
];

export default routes;