import { createContext, useContext } from 'react';
import { CurrentUserViewModel } from '../../utils/accountWebProxy';

export const AuthenticationContext = createContext<{
    setUser: (user: CurrentUserViewModel) => void;
    user: null | undefined | CurrentUserViewModel;
}>({
    setUser: (user: CurrentUserViewModel) => {/*empty*/ },
    user: null,
});

export const useAuthentication = () => useContext(AuthenticationContext);
