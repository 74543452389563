import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import FsLightbox from 'fslightbox-react';
import VideoTile from '../../Media/VideoTile';
import CookieBlockedMessage from '../../CookieBlockedMessage';
import { getProviderFromUrl, CheckVideoEmbedCookieConsent } from '../../../utils/videoEmbedCookieHelper';

const Root = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const VideoWrap = styled.div`
    position: relative;
    width: 100%;
    background: ${p => p.theme.color.primary};
    scroll-margin-top: ${p => p.theme.header.height}px;
`;

const CookieMessageWrap = styled.div`
    display: block;
    width: 100%;
    height:auto;
    margin: 0;
    color: #fff;
    padding: 15% 10%;
    background-color: lightgrey;
    min-height: 388px;
`;

const getUrlOrIframe = (url: string) => {
  if (url.includes('vimeo')) {
    return (<iframe
      src={url}
      width="1920px"
      height="1080px"
      allow="autoplay; fullscreen"
      allowFullScreen
    />);
  }
  return url;
};

const MarkdownLinkButton: FunctionComponent<{ data: string }> = (props) => {
  const data: { url: string, thumbnail: string, title: string, id: number } = JSON.parse(props.data);

  const [lightBox, setLightBox] = useState({
    toggler: false,
    slide: 0
  });

  function showLightBox() {
    setLightBox({
      toggler: !lightBox.toggler,
      slide: 0
    });
  }

  const provider = getProviderFromUrl(data.url);
  const isAllowed = CheckVideoEmbedCookieConsent(data.url, provider);

  useEffect(() => {
    const query = window.location.search;
    if (query.length > 0) {
      const params = new URLSearchParams(query);
      const videoId = params.get('p');
      const videoIndex = videoId ? parseInt(videoId, 10) : null;
      if (isAllowed && videoIndex && videoIndex === data.id) {
        showLightBox();
      }
    }
  }, [isAllowed]);

  return (
    <Root>
      <VideoWrap id={data.title}>
        {isAllowed ?
          <>
            <VideoTile onClickFn={() => showLightBox()} thumbnail={data.thumbnail} alt={data.title} align="center"/>
          </>
          : <CookieMessageWrap><CookieBlockedMessage anchor="ThirdPartyEmbeds" /></CookieMessageWrap>
        }
      </VideoWrap>
      <FsLightbox
            toggler={lightBox.toggler}
            sources={[getUrlOrIframe(data.url)]}
            sourceIndex={lightBox.slide}
          />
    </Root>);
};

export default MarkdownLinkButton;