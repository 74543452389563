import React, { ForwardRefRenderFunction } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useClientConfig } from '../../ClientConfig';

const CaptchaFormField: ForwardRefRenderFunction<any, any> = (_, ref) => {
    const clientConfig = useClientConfig();

    return (
        <ReCAPTCHA
            ref={ref}
            size="invisible"
            sitekey={clientConfig.captchaSiteKey}
        />
    );
};

export default React.forwardRef(CaptchaFormField);
