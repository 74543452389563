import MuiButton from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';
import theme from '../../theme/theme';

// use only as a base for other buttons
export const PrimaryColorTextMuiButton: any = withStyles(createStyles<string, { height?: PrimaryColorTextMuiButtonHeight }>({
    root: {
        'a&,a&:visited,a&:hover,a&:active': {
            color: theme.color.primary,
        },
        height: props => props.height === 'formInput' ? 56 : undefined
    }
}))(MuiButton);

export type PrimaryColorTextMuiButtonHeight = 'normal' | 'formInput';