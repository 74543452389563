import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import media from '../../theme/media';
import HeaderMob from './HeaderMob';
import HeaderDesktop from './HeaderDesktop';

const HeaderFixedWrap = styled.div`
    position: fixed;
    top: 0;
    width:100%;
    display: none;
    &.show-enter {
        opacity: 0;
        display: block;
    }
    &.show-enter-active {
        opacity: 1;
        transition: opacity 0.4s;
        display: block;
    }
    &.show-enter-done {
        opacity: 1;
        display: block;
    }
    &.show-exit {
      opacity: 1;
      display: block;
    }
    &.show-exit-active {
        opacity: 0;
        transition: opacity 0.1s;
        display: block;
    }
    &.show-exit-done {
        display: none;
    }
`;

const HeaderMobContainer = styled.div`
    display: block;
    z-index: 1000;
    ${media.greaterThan('navMob')`
       display: none;
    `}
`;
const HeaderDesktopContainer = styled.div`
    display: none;
    z-index: 1000;
    ${media.greaterThan('navMob')`
       display: block;
    `}
`;

const Header: FunctionComponent = () => {
    const [showFixed, setShowFixed] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY >= 200) {
                setShowFixed(true);
            }
            if (window.scrollY < 200) {
                setShowFixed(false);
            }
        };
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <>
            <HeaderMobContainer>
                <HeaderMob />
            </HeaderMobContainer>
            <HeaderDesktopContainer>
                <HeaderDesktop />
                <CSSTransition
                    in={showFixed}
                    timeout={400}
                    classNames="show">
                        {/*mui-fixed - https://material-ui.com/getting-started/faq/#why-do-the-fixed-positioned-elements-move-when-a-modal-is-opened */}
                    <HeaderFixedWrap className="mui-fixed"> 
                        <HeaderDesktop />
                    </HeaderFixedWrap>
                </CSSTransition>
            </HeaderDesktopContainer>
        </>
    );
};

export default Header;