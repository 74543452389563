import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import Container from './Container';
import Seo, { SeoProps } from '../Seo';
import { colsToWidth } from '../../utils/themeHelpers';
import theme from '../../theme/theme';

const Root = styled.div`
    padding-top: 117px;
`;

const Title = styled.h1`
    margin-bottom: 20px;
`;

const Content = styled.div`
    margin: 0 auto;
    max-width: ${colsToWidth(theme, 8)}px;
    text-align: center;
`;

const ButtonRow = styled.div`
      padding-top: 20px;
`;

const TitleImage = styled.img`
    margin-bottom: 43px;
`;

const TitleRow = styled.span`
    display: block;
`;

const MessagePage: FunctionComponent<Props> = ({ children, action, ...props }) => (
    <Root>
        <Container>
            <Seo {...props} title={props.metaTitle || (props.titleRows?.length ? props.titleRows[0] : undefined)} />
            <Content>
                {props.titleImageSrc && <TitleImage src={props.titleImageSrc} />}
                <Title>
                    {props.titleRows.map(title =>
                        <TitleRow key={title}>{title}</TitleRow>
                    )}
                </Title>
                {children}
                {action && <ButtonRow>{action}</ButtonRow>}
            </Content>
        </Container>
    </Root>
);

export interface Props extends SeoProps {
    metaTitle?: string;
    titleRows: string[];
    action?: ReactNode;
    titleImageSrc?: string;
}

export default MessagePage;
