import { NavItemConfig } from '../../navItems';
import {
    matchPath
} from 'react-router-dom';

const isNavItemActive = (item: NavItemConfig, pathName: string) =>
    item.path && !!matchPath(pathName, {
        path: item.path,
        exact: false
    }) || item.subItems && !!item.subItems.find(subItem => subItem.path && !!matchPath(pathName, {
        path: subItem.path,
        exact: false
    }));

export default isNavItemActive;
