import React, { FunctionComponent, useEffect, useState, useRef, createRef } from 'react';
import styled from 'styled-components';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import { colsToWidth } from '../../utils/themeHelpers';
import FormObserver from './FormObserver';
import { ObjectSchema } from 'yup';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { SOMETHING_WENT_WRONG } from '../../../resx';
import CaptchaFormField from './CaptchaFormField';

const StyledForm = styled(FormikForm) <LayoutProps>`
    max-width: ${p => colsToWidth(p.theme, p.width || 8)}px;
`;

const Form: FunctionComponent<Props> = (props) => {

    const [pristineValues, setPristineValues] = useState(props.initialValues);

    const snackbar = {
        enqueueSnackbar: props.enqueueSnackbar,
        closeSnackbar: props.closeSnackbar,
    };

    const isUnmounting = useRef(false);

    const captchaRef = createRef<any>();

    useEffect(() => {
        return () => {
            isUnmounting.current = true;
        };
    }, []);

    return (
        <Formik
            enableReinitialize={props.enableReinitialize}
            initialValues={props.initialValues}
            validationSchema={props.validationSchema}
            onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
                if (props.onSubmit && (!props.preventSubmitPristine || values !== pristineValues)) {
                    const captchaTokenPromise = captchaRef.current && captchaRef.current.executeAsync() || Promise.resolve(null);
                    captchaTokenPromise
                        .then((captchaToken) =>
                            props.onSubmit!({
                                values,
                                snackbar,
                                captcha: captchaToken
                            }))
                        .then(r => {
                            if (!isUnmounting.current) {
                                if (!props.avoidResetOnSubmit) {
                                    resetForm();
                                }
                                setPristineValues(values);
                            }
                            return r;
                        })
                        .catch(err => {
                            if (!isUnmounting.current) {
                                if (err && err.data) {
                                    if (err.data.fields && (err.status === 400 || err.status === 403)) {
                                        for (const fieldErr of err.data.fields) {
                                            setFieldError(fieldErr.name, fieldErr.message);
                                        }
                                    } else {
                                        props.enqueueSnackbar(err.data.message || SOMETHING_WENT_WRONG, {
                                            variant: 'error',
                                        });
                                    }
                                } else {
                                    props.enqueueSnackbar(SOMETHING_WENT_WRONG, {
                                        variant: 'error',
                                    });
                                }
                            }
                            throw err;
                        })
                        .finally(() => !isUnmounting.current && setSubmitting(false));
                } else {
                    setSubmitting(false);
                }
            }}
        >
            {({
                handleSubmit,
                setFieldValue,
            }) => (

                    <StyledForm width={props.width} onSubmit={handleSubmit}>
                        {props.children}
                        <FormObserver onChange={({ prevValues, nextValues }) => props.onChange && props.onChange({ prevValues, nextValues, setFieldValue, snackbar })} />
                        {props.enableCaptcha && <CaptchaFormField ref={captchaRef} />}
                    </StyledForm>
                )}
        </Formik>
    );
};

interface LayoutProps {
    width?: number;
}

export interface OnChangeParams {
    prevValues: any;
    nextValues: any;
    snackbar: WithSnackbarProps;
    setFieldValue(field: string, value: any, shouldValidate?: boolean): void;
}

export interface OnSubmitParams {
    values: any;
    snackbar: WithSnackbarProps;
    captcha: string | null;
}

interface Props extends WithSnackbarProps {
    enableReinitialize?: boolean;
    initialValues: any;
    avoidResetOnSubmit?: boolean;
    validationSchema?: ObjectSchema<any>;
    onChange?: (params: OnChangeParams) => void;
    onSubmit?: (params: OnSubmitParams) => Promise<any>;
    width?: number;
    preventSubmitPristine?: boolean;
    enableCaptcha?: boolean;
}

export const useForm = (): { setValues: (values: any) => void } => useFormikContext();

export default withSnackbar(Form);
