import { createMuiTheme, StyleRules, ThemeOptions } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { AutocompleteClassKey } from '@material-ui/lab';
import theme from './theme';

// TODO: MuiAutocomplete
// https://github.com/mui-org/material-ui/issues/19692
// import type '@material-ui/lab/themeAugmentation'; 
// TODO: update razzle + migrate to eslint from tslint

const overrides: Overrides = {
  MuiOutlinedInput: {
    root: {
      borderRadius: 0
    },
    notchedOutline: {
      borderColor: theme.color.span,
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: theme.font.size.formLabelPx / .75, // .75 - label is scaled down by .75 when it is shrunk
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: theme.color.span,
    },
    outlined: {
      '&$shrink:not(:empty)': {
        backgroundColor: theme.color.background, // because the label width is wrong - width of the notch depends on the font size of the input (https://github.com/mui-org/material-ui/issues/15918)
        paddingRight: '6px'
      }
    }
  },
  MuiInputBase: {
    input: {
      color: theme.color.text,
      wordBreak: 'normal',
      backgroundColor: theme.color.background,
      '&::placeholder': {
        color: theme.color.textLight,
        opacity: 1
      }
    },
  },
  MuiButton: {
    root: {
      height: `${theme.controls.buttonHeightPx}px`,
      fontSize: theme.font.size.buttonPx,
      borderRadius: 0,
      minWidth: theme.button.minWidth,
      maxWidth: 240,
      whiteSpace: 'nowrap',
      border: `solid 1px ${theme.color.primary}`,
      '&:hover': {
        backgroundColor: 'transparent'
      },
      'a&,a&:visited,a&:hover,a&:active': {
        color: theme.color.background,
        fontWeight: 'bold',
        textDecoration: 'none',
      },
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    outlinedPrimary: {
      '&,a&,a&:visited': {
        color: theme.color.span,
      },
      border: `1px solid ${theme.color.span}`,
      transition: 'color .5s ease-in-out, border .5s ease-in-out',
      '&:hover,a&:hover,a&:active': {
        color: theme.color.primary,
        backgroundColor: 'transparent'
      },
    },
    containedPrimary: {
      overflow: 'hidden',
      position: 'relative',
      transition: 'color .5s ease-in-out',
      '&::before': {
        position: 'absolute',
        top: 0,
        right: -50,
        bottom: 0,
        left: 0,
        height: `${theme.controls.buttonHeightPx}px`,
        content: '""',
        borderRight: '50px solid transparent',
        borderBottom: `${theme.controls.buttonHeightPx}px solid ${theme.color.background}`,
        transform: 'translateX(-100%) translateX(-10px)',
        zIndex: 0,
        boxSizing: 'border-box',
        transition: '.5s ease-in-out',
      },
      '&:hover,a&:hover,a&:active,&.hover,a&.hover': {
        color: theme.color.primary,
        backgroundColor: theme.color.primary,
        boxShadow: 'none',
        '&::before': {
          transform: 'translateX(0)',
        },
      },
    },
    text: {
      minWidth: 'unset',
      maxWidth: 'unset',
      border: 'none',
      padding: '6px 12px',
    },
    textPrimary: {
      '&:hover': {
        backgroundColor: theme.color.backgroundAlt,
      },
    },
    label: {
      zIndex: 2,
    }
  },
  MuiIconButton: {
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      }
    }
  },
  MuiSlider: {
    rail: {
      border: `solid 1px ${theme.color.borderDark}`,
      backgroundColor: 'none',
      height: 5,
      opacity: 1,
    },
    track: {
      backgroundColor: 'none',
    },
    thumb: {
      height: 17,
      width: 17,
      border: `solid 5px ${theme.color.primary}`,
      color: 'white',
      marginTop: -5,
      marginLeft: -8,
      '&::before': {
        background: 'none',
        display: 'block',
        position: 'absolute',
        content: '""',
        border: `solid 1px ${theme.color.borderDark}`,
        top: -5,
        right: -5,
        bottom: -5,
        left: -5,
        borderRadius: '50%',
      }
    }
  },
  MuiTextField: {
    root: {
      backgroundColor: theme.color.background,
    }
  }
};

const autoCompleteOverrides: Partial<StyleRules<AutocompleteClassKey>> = {
  option: {
    margin: 0
  }
};

const overridesWithLabs: any = {
  ...overrides,
  MuiAutocomplete: autoCompleteOverrides
};

const materialTheme = createMuiTheme({
  typography: {
    fontFamily: theme.font.fontFamily.join(','),
    htmlFontSize: theme.font.size.basePx,
    fontSize: theme.font.size.formRem * 14 * theme.font.size.basePx / 16, // https://material-ui.com/customization/typography/#font-size
  },
  palette: {
    primary: {
      main: theme.color.primary,
    },
    secondary: {
      main: theme.color.primary, // TODO
    },
    // error: {
    //   // main: theme.color.primary, // TODO
    // },
    background: {
      default: theme.color.background,
    },
    text: {
      primary: theme.color.text,
    }
  },
  overrides: overridesWithLabs,
  props: {
    MuiInputLabel: {
      shrink: true
    },
    MuiOutlinedInput: {
      notched: true
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary',
      disableRipple: true,
    },
  }
});

export default materialTheme;