import React from 'react';
import { PrimaryLinkButton } from './Button/LinkButton';
import MessagePage from './layout/MessagePage';
import NotFoundImage from '../assets/NotFoundImage.jpg';

const homeLink = <PrimaryLinkButton to="/">Zur Startseite</PrimaryLinkButton>;

const GenericError = () =>
      <MessagePage titleImageSrc={NotFoundImage} titleRows={['Ein interner Fehler ist aufgetreten.', 'Bitte kommen Sie zu einem späteren Zeitpunkt wieder.']} metaTitle="Ein interner Fehler ist aufgetreten!" />;

const MaintenanceError = () =>
      <MessagePage titleImageSrc={NotFoundImage} titleRows={['Leider ist die Webseite derzeit wegen Wartungsarbeiten nicht erreichbar.', 'Einige Funktionen können beeinträchtigt sein. Bitte schauen Sie später wieder vorbei. Vielen Dank für Ihre Geduld.']} metaTitle="Ein interner Fehler ist aufgetreten!" />;

export const ErrorNotFound = () =>
      <MessagePage titleImageSrc={NotFoundImage} action={homeLink} titleRows={['Diese seite wurde nicht gefunden!']} />;

const Error = (props: ErrorProps) =>
      props.statusCode === 404 ? <ErrorNotFound /> :
            props.model?.isPortegoAvailable === false ? <MaintenanceError /> : <GenericError />;

export interface ErrorProps {
      statusCode: number;
      model: {
            isPortegoAvailable: boolean
      };
}

export default Error;