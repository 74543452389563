import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../layout/Container';
import logo from '../../assets/Logo.svg';
import getNavItems, { NavItemConfig } from '../../navItems';
import NavItem from './NavItem';
import { useAuthentication } from '../auth/authenticationContext';
import { pxToRem } from '../../theme/theme';
import StLink from '../StLink';

const Logo = styled(Link)`
    grid-area: logo;
    height: 78px;
    img{
        height:100%
    }
`;

const Menu = styled.ul`
    grid-area: nav;
    justify-self: right;
    display: grid;
    list-style: none;
    margin: 0;
    padding: 0;
    grid-column-gap: 23px;
    grid-auto-flow: column;
    li:before{
        content: '';
    }
`;

const MenuRoot = styled.div`
    width: 100%;
    display: grid;
    grid: 
        "logo nav" 1fr
        / max-content auto;
    grid-column-gap: 31px; 
    align-items: center;
    height: ${p => p.theme.header.menuHeight}px;
`;

const InfoStrip = styled.div`
    text-align: right;
    font-size: ${pxToRem(13)}rem;
    height: ${p => p.theme.header.infoStripHeight}px;
`;

const InfoStripDelimeter = styled.span`
    font-weight: bold;
    margin: 0 20px;
`;

const InfoStripLink = styled(StLink)`
     &, &:visited, &:focus, &:active, &:hover{
        color: currentColor ;
        text-decoration: none;
    }
    &:hover{
        color: ${p => p.theme.color.primary} ;
        text-decoration: underline;
    }
`;

const HeaderDesktop: FunctionComponent = () => {
    const [expandedNavItem, setExpandedNavItem] = useState<NavItemConfig | null>(null);
    const { user } = useAuthentication();
    const navItems = getNavItems({ user });

    const location = useLocation();
    useEffect(() => { // collapse items on navigation
        setExpandedNavItem(null);
    }, [location.pathname]);

    const expandItem = (item: NavItemConfig) => setExpandedNavItem(item);
    const collapseItem = () => setExpandedNavItem(null);

    return (
        <>
            <Container background="alt">
                <InfoStrip>
                    <InfoStripLink to="https://frieden-informatik.at" external>frieden-informatik.at</InfoStripLink>
                    <InfoStripDelimeter>|</InfoStripDelimeter>
                    <InfoStripLink to="https://sauberreinzufrieden.frieden.at" external>sauberreinzufrieden.frieden.at</InfoStripLink>
                </InfoStrip>
            </Container>

            <Container>
                <MenuRoot>
                    <Logo to="/"><img src={logo} alt="logo" /></Logo>
                    <Menu>
                        {navItems.map(item => (<NavItem key={item.title} item={item} isExpanded={item === expandedNavItem} onMouseEnter={expandItem} onMouseLeave={collapseItem} onClick={expandItem} />))}
                    </Menu>
                </MenuRoot>
            </Container>
        </>
    );
};

export default HeaderDesktop;
