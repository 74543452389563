import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { NavItemConfig } from '../../navItems';
import NavLink from './NavLink';
import Icon from '../Icon';
import media from '../../theme/media';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router-dom';
import isNavItemActive from './isNavItemActive';

// margin-left - make a room for expanded ::after
const NavLinkContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s ease;
    white-space: nowrap;
    position: relative;
    padding: 0;
    margin: 0;
    font-size: ${p => `${p.theme.font.size.menuSubMobRem}rem`};
    font-weight: normal;
    margin-left: 20px;
    transition: margin-left 0.2s;

    ${media.greaterThan('navMob')`
        font-size: ${p => `${p.theme.font.size.menuSubRem}rem`};
        font-weight: bold;
    `}

    &::after {
        display: block;
        content: '';
        height: 4px;
        background: ${p => p.theme.color.primary};
        bottom: 0;
        width: 0;
        right: 0;
        transition: all 0.2s;
    }
    &.active,
    &:hover{
        margin-left: 0;
        color: ${p => p.theme.color.primary} ;
        &::after {
            margin-left: 10px;
            width: 10px;
        }
    }  
    ${media.greaterThan('navMob')`
        justify-content: flex-end;
    `}    
`;

const ItemIcon = styled(Icon)`
    margin-right: 10px;
`;

const Separator = styled.div`
    height: 1px;
    background: ${p => p.theme.color.separator};
    margin: 5px 0;
`;

const Heading = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    font-size: ${p => `${p.theme.font.size.menuHeadingMobRem}rem`};
    color: ${p => p.theme.color.textLight};
    text-align: center;
    ${media.greaterThan('navMob')`
        font-size: ${p => `${p.theme.font.size.menuHeadingRem}rem`};
        text-align: right;
    `} 
`;

const Root = styled.li`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const SubNavItem: FunctionComponent<Props> = (props) => {
    const isActive = isNavItemActive(props.item, props.location.pathname);

    return (
        <Root>
            {props.item.hasSeparator && <Separator />}
            {props.item.path ?
                <NavLink item={props.item} >
                    <NavLinkContent className={`${isActive ? 'active' : ''}`}>
                        {props.item.icon && <ItemIcon {...props.item.icon} />}
                        {props.item.title}
                    </NavLinkContent>
                </NavLink>
                : <Heading>
                    {props.item.title}
                </Heading>
            }
        </Root>
    );
};

interface Props extends RouteComponentProps {
    item: NavItemConfig;
}

export default withRouter(SubNavItem);
