import React, { FunctionComponent } from 'react';
import { useField } from 'formik';
import { Switch } from '@material-ui/core';

const FormAutocompleteField: FunctionComponent<Props> = (props) => {
    // TODO: validation - not required for now
    const [field, , helpers] = useField(props.name);
    const onChange = (event, newValue) => {
        helpers.setValue(newValue);
    };
    return (
        <Switch
            checked={field.value}
            onChange={onChange}
            disabled={props.disabled}
            color="primary"
            name={props.name}
            inputProps={{ 'aria-label': props.label }}
        />
    );
};

interface Props {
    name: string;
    label: string;
    disabled?: boolean;
}

export default FormAutocompleteField;
