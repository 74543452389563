import { CookieConsentKeys as CookieConsentKey, useCookieConsent } from '../components/Cookie/CookieConsentContext';

export enum EVideoProvider {
    youtube = 1,
    vimeo = 2
}

const videoProviderCookie = new Map<EVideoProvider, CookieConsentKey>([
    [EVideoProvider.vimeo, 'videoEmbedsVimeo'],
    [EVideoProvider.youtube, 'videoEmbedsYoutube']
]);

const getRequiredCookieConsent: (src: string, provider?: EVideoProvider) => CookieConsentKey[] = (src: string, provider?: EVideoProvider) => {
    if (!provider) {
        provider = getProviderFromUrl(src);
    }

    if (provider) {
        const cookie = videoProviderCookie.get(provider);
        if (cookie) {
            return [cookie];
        }
    }
    return ['videoEmbedsVimeo', 'videoEmbedsYoutube']; // fallback - check all
};

export const getProviderFromUrl = (src: string) => {
    if (/^(https?:\/\/)?(www\.|player\.)?vimeo\.com/i.test(src)) {
        return EVideoProvider.vimeo;
    }
    if (/^(https?:\/\/)?(www\.)?(youtu\.be\/|youtube\.com\/)/i.test(src)) {
        return EVideoProvider.youtube;
    }
    return undefined;
};

export function CheckVideoEmbedCookieConsent(src: string, provider?: EVideoProvider | undefined): boolean {  
    const { cookieConsent } = useCookieConsent();
    const cookieConsentNames = getRequiredCookieConsent(src, provider);
    return !!cookieConsent && !cookieConsentNames.find(c => !cookieConsent[c]);
}