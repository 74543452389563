import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import closeIcon from '../assets/sprite/close.svg';
import Icon from './Icon';
import media from '../theme/media';

const CloseIcon = styled(Icon) <{ isDisabled?: boolean }>`
    margin: 30px 30px 0 auto;
    cursor: ${p => p.isDisabled ? 'normal' : 'pointer'};
    flex: 0 0 19px;
`;

const Content = styled.div`
    margin: 20px;
    ${media.greaterThan('small')`
        margin: 30px 50px 50px 50px;
    `}
`;

const Title = styled.h2`
    margin-bottom: 27px;
`;

const DialogPopup: FunctionComponent<DialogPopupProps> = ({ children, title, open, onClose, disableClose, maxWidth, hideClose }) => {
    const handleClose = () => {
        if (!disableClose) {
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={maxWidth}>
            {!hideClose && <CloseIcon glyph={closeIcon} size="19px" onClick={handleClose} isDisabled={disableClose} aria-label="schließen" />}
            <Content>
                {!!title && <Title>{title}</Title>}
                {children}
            </Content>
        </Dialog>
    );
};

export interface DialogPopupProps {
    title: string;
    open: boolean;
    onClose: () => void;
    disableClose?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    hideClose?: boolean;
}

export default DialogPopup;
