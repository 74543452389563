import { DefaultTheme } from 'styled-components';

const color = {
    primary: '#B44114',
    text: '#707070',
    span: '#585858',
    textLight: '#C5C5C5',
    background: '#ffffff',
    backgroundAlt: '#F6F6F6',
    backgroundLight: '#FAFAFA',
    backgroundDark: '#F1F1F1',
    backgroundHover: '#efe2dd',
    separator: '#F6F6F6',
    qnaSeparator: '#9F9F9F',
    topProgress: '#B44114BF',
    facebook: '#4267B2',
    google: '#DD4B39',
    borderLight: '#AEAEAE',
    borderDark: '#585858',
};

const BASE_PX = 18;
const BASE_COL_WIDTH_PX = 82;
const GUTTER_WIDTH_PX = 20;
const COL_CNT = 12;

export const  pxToRem = (px: number) => parseFloat((px / BASE_PX).toFixed(4));

const font = {
    fontFamily: [
        'Barlow Semi Condensed',
        'sans-serif',
    ],
    size: {
        basePx: BASE_PX,
        formRem: pxToRem(16),
        formSectionTitleRem: pxToRem(16),
        formLabelPx: 11.25,
        menuRem: pxToRem(14),
        menuSubRem: pxToRem(13),
        menuHeadingRem: pxToRem(10),
        menuMobRem: 1,
        menuSubMobRem: pxToRem(15),
        menuHeadingMobRem: pxToRem(12),
        buttonPx: 13,
        buttonSmallPx: 11,
        baseLineHeightPx: 32,
        sliderLabelRem: pxToRem(17),
        sliderValueRem: pxToRem(13),
        mobileBodyMinRem: pxToRem(16),
    }
};

const theme: DefaultTheme = {
    color,
    font,
    grid: {
        containerPad: 21,
        baseColWidth: BASE_COL_WIDTH_PX,
        gutter: GUTTER_WIDTH_PX,
        columnCnt: COL_CNT,
        containerWidth: BASE_COL_WIDTH_PX * COL_CNT + GUTTER_WIDTH_PX * (COL_CNT - 1), // 1204,
    },
    header: {
        infoStripHeight: 29,
        menuHeight: 90,
        get height() { return this.infoStripHeight + this.menuHeight; },
        heightMob: 56,
    },
    hero: {
        defaultHeight: 465,
        defaultHeightMob: 265,
        largeHeight: 670,
        largeHeightMob: 265,
    },
    controls: {
        buttonHeightPx: 43
    },
    project: {
        defaultCardWidth: 388
    },
    button: {
        minWidth: 166,
    },
    structure: {
        cell: {
            minWidth: 152,
            maxWidth: 306,
            mainMinWidth: 227,
            mainMaxWidth: 360,
            minHeight: 43
        },
        font: {
            titleSize: pxToRem(18),
            baseSize: pxToRem(13)
        }
    }
};

export default theme;