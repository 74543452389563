import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Container from '../layout/Container';
import logo from '../../assets/Logo.svg';
import getNavItems, { NavItemConfig } from '../../navItems';
import NavItem from './NavItem';
import Hamburger from './Hamburger';
import Collapse from 'react-bootstrap/Collapse';
import { useAuthentication } from '../auth/authenticationContext';
import StLink from '../StLink';
import { pxToRem } from '../../theme/theme';

const Root = styled.div` // to push content
    height: ${p => p.theme.header.heightMob}px; 
`;

const Logo = styled(Link)`
    grid-area: logo;
    height: 38px;
    img{
        height:100%
    }
`;

const MenuHamburger = styled(Hamburger)`
    grid-area: hamburger;
`;
const Menu = styled.ul`
    list-style: none;
    margin: 0;

    &.collapsing {
        height: 0;
        overflow: hidden;
        transition: height .2s ease;
    }
    &.collapse:not(.show) {
        display: none;
    }
`;

const MenuContent = styled.div`
    padding: 36px 0 63px 0;
`;

const Main = styled.div`
    width: 100%;
    display: grid;
    grid: 
      "logo hamburger" 1fr
      / max-content auto;
    grid-column-gap: 31px; 
    align-items: center;
    justify-items: right;
    height: ${p => p.theme.header.heightMob}px;
`;

const HeaderFixedMobWrap = styled.div`
    position: fixed;
    top: 0;
    width:100%;
`;

const ExternalLinks = styled.div`
    margin-top: 40px;
    display: grid;
    text-align: center;
    font-size: ${pxToRem(15)}rem;
`;

const HeaderMob: FunctionComponent = () => {
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const [expandedNavItem, setExpandedNavItem] = useState<NavItemConfig | null>(null);
    const { user } = useAuthentication();
    const navItems = getNavItems({ user });

    const location = useLocation();
    useEffect(() => { // collapse items on navigation
        setExpandedNavItem(null);
        setIsMenuExpanded(false);
    }, [location.pathname]);

    const onItemClick = (item: NavItemConfig) => setExpandedNavItem(item === expandedNavItem ? null : item);
    const onHamburgerClick = () => setIsMenuExpanded(!isMenuExpanded);

    return (
        <Root>
            <HeaderFixedMobWrap>
                <Container>
                    <Main>
                        <Logo to="/"><img src={logo} alt="logo" /></Logo>
                        <MenuHamburger onClick={onHamburgerClick} isOpen={isMenuExpanded} />
                    </Main>
                    <Collapse in={isMenuExpanded}>
                        <Menu>
                            <MenuContent>
                                {navItems.map(item => (<NavItem key={item.title} item={item} isExpanded={item === expandedNavItem} onClick={onItemClick} />))}
                                <ExternalLinks>
                                    <StLink to="https://frieden-informatik.at" external>www.frieden-informatik.at</StLink>
                                    <StLink to="https://sauberreinzufrieden.frieden.at" external>www.sauberreinzufrieden.frieden.at</StLink>
                                </ExternalLinks>
                            </MenuContent>
                        </Menu>
                    </Collapse>
                </Container>
            </HeaderFixedMobWrap>
        </Root>
    );
};

export default HeaderMob;
