import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PrimaryLinkButton } from '../../Button/LinkButton';

const Root = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const MarkdownLinkButton: FunctionComponent<{ data: string }> = (props) => {
  const data: { url: string, label: string } = JSON.parse(props.data);
  const isExternal = /^(?:[a-z]+:)?\/\//i.test(data.url);
  return (
    <Root>
      <PrimaryLinkButton to={data.url} external={isExternal}>{data.label}</PrimaryLinkButton>
    </Root>);
};

export default MarkdownLinkButton;