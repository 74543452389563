import React, { FunctionComponent } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { withRouter, matchPath } from 'react-router';
import { NavItemConfig } from '../../navItems';
import StLink from '../StLink';

const BaseLink = styled.div<Props>`
    &, &:visited, &:focus, &:active, &:hover{
        color: currentColor ;
        text-decoration: none;
    }
    &:hover{
        color: currentColor ;
        text-decoration: none;
    }
`;

const StyledReactLink = ({ to, className, children, external, externalTarget }) => (<StLink external={external} externalTarget={externalTarget} to={to} className={className}>{children}</StLink>); // To prevent passing of all props to Link
const ActiveLink = styled(BaseLink).attrs({ as: StyledReactLink })`
    cursor: pointer;
` as FunctionComponent<{ to: string, className?: string, inheritColor?: boolean, external?: boolean, externalTarget?: '_self' | '_blank' }>;

const PassiveLink = styled(BaseLink)`
    cursor: default;
`as FunctionComponent<{ className?: string, inheritColor?: boolean }>;

const NavLink: FunctionComponent<Props> = ({ item, ...props }) => {
    return (
        <>
            {item.path ?
                <ActiveLink to={item.path} external={item.external} externalTarget={item.externalTarget} {...props}>{props.children}</ActiveLink>
                : <PassiveLink {...props}>{props.children}</PassiveLink>
            }
        </>);
};

interface Props extends RouteComponentProps {
    item: NavItemConfig;
    className?: string;
    inheritColor?: boolean;
}

export default withRouter(NavLink);