import { createGlobalStyle } from 'styled-components';
import media from './media';
import { pxToRem } from './theme';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Barlow Semi Condensed';
        src: url('/fonts/barlow-semi-condensed-700.eot');
        src: url('/fonts/barlow-semi-condensed-700.eot?#iefix') format('embeded-opentype'),
            url('/fonts/barlow-semi-condensed-700.ttf') format('truetype'),
            url('/fonts/barlow-semi-condensed-700.woff2') format('woff2'),
            url('/fonts/barlow-semi-condensed-700.svg#barlowSemiCondensedBold') format('svg');
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: 'Barlow Semi Condensed';
        src: url('/fonts/barlow-semi-condensed-regular.eot');
        src: url('/fonts/barlow-semi-condensed-regular.eot?#iefix') format('embeded-opentype'),
            url('/fonts/barlow-semi-condensed-regular.ttf') format('truetype'),
            url('/fonts/barlow-semi-condensed-regular.woff2') format('woff2'),
            url('/fonts/barlow-semi-condensed-regular.svg#barlowSemiCondensedRegular') format('svg');
        font-weight: normal;
        font-style: normal;
    }
    body {
        background: ${p => p.theme.color.background};
        color: ${p => p.theme.color.text};
        word-break: break-word; // TODO: remove
    }
    form {
        margin: 0;
    }
    button {
        // reset
        display: flex;
        text-transform: none;
        max-width: none;
        border: none;
        font-family: inherit;
        cursor: pointer;
        color: ${p => p.theme.color.text};
        background: none;
        align-items: center;
        justify-content: space-between;
        &, &:hover {
            color: ${p => p.theme.color.text};
            background: none;
            outline-style: none;
            outline-color: transparent;
        }   
        &:focus {
            outline-style: none;
            outline-color: transparent;
        }                
    }
    html {
        scroll-behavior: smooth;
    }
    h2 {
        font-size: ${pxToRem(18)}rem;
        line-height: ${pxToRem(24)}rem;
        span{
            font-size: 0.9em;
        }
        ${media.greaterThan('small')`
            font-size: ${pxToRem(28)}rem;
            line-height: ${pxToRem(34)}rem;
            span{
            font-size: 0.6em;
            }
        `}
    }
`;

export default GlobalStyle;