import WebProxyClient from './webProxyClient';
import { ProspectHomePageViewModel } from './prospectWebProxy';

const createAccountWebProxy = ({ user }: { user: CurrentUserViewModel | undefined | null }) => {
    const client = new WebProxyClient('/konto', { csrfToken: user?.csrfToken });

    return {
        registerEmailPassword: (model: EmailPasswordInputModel) => client.post('/registerEmailPassword', model),
        finishRegistration: (model: FinishRegistrationViewModel) => client.post('/finishRegistration', model),
        resendRegistrationVerificationEmail: (model: EmailInputModel) => client.post('/resendRegistrationVerificationEmail', model),
        loginEmailPassword: (model: EmailPasswordInputModel) => client.post('/loginEmailPassword', model),
        getCurrentUser: (): Promise<CurrentUserViewModel> => client.get('/getCurrentUser'),
        updateProspectContactDetails: (model: ProspectContactDetailsInputModel): Promise<any> => client.post('/updateProspectContactDetails', model),
        updateProspectPersonalInformations: (model: ProspectPersonalInformationsInputModel): Promise<any> => client.post('/updateProspectPersonalInformations', model),
        resetPassword: (model: EmailInputModel) => client.post('/resetpassword', model),
        resendResetPasswordVerificationEmail: (model: EmailInputModel) => client.post('/resendResetPasswordVerificationEmail', model),
        finishResetPassword: (model: EmailPasswordInputModel) => client.post('/finishResetPassword', model),
        checkPassword: (model: PasswordInputModel) => client.post('/checkPassword', model),
        changeEmail: (model: EmailPasswordInputModel) => client.post('/changeEmail', model),
        changePassword: (model: ChangePasswordInputModel) => client.post('/changepassword', model),
        deleteAccount: () => client.post('/deleteAccount'),
        finishDeleteAccount: (model: FinishDeleteAccountViewModel) => client.post('/finishDeleteAccount', model),
        removeExternalLogin: (model: RemoveExternalLoginInputModel) => client.post('/removeExternalLogin', model),

        testCsrf: (): Promise<any> => client.post('/testCsrf', {}), // TODO: remove
    };
};

export default createAccountWebProxy;

interface EmailPasswordInputModel {
    email: string;
    password: string;
}

interface EmailInputModel {
    email: string;
}

interface PasswordInputModel {
    password: string;
}

interface ChangePasswordInputModel {
    oldPassword: string;
    newPassword: string;
}

interface FinishRegistrationViewModel {
    idUser: string;

    token: string;

    gender: number;

    preTitle: string;
    postTitle: string;

    firstName: string;
    lastName: string;

    street: string;
    postCode: string;
    city: string;

    phone: string;
}

export interface CurrentUserViewModel {
    prospect: ProspectHomePageViewModel;
    isAuthenticated: boolean;
    csrfToken: string;
    loginProviders: UserLoginProviderViewModel[];
    passwordConfirmed: boolean;
}
export interface UserLoginProviderViewModel {
    loginProvider: string;
    providerKey: string;
    providerDisplayName: string;
}

export interface ProspectContactDetailsInputModel {
    street: string;
    postCode: string;
    city: string;
    phone: string;
}

export interface ProspectPersonalInformationsInputModel {
    preTitle: string;
    postTitle: string;
    firstName: string;
    lastName: string;
}

export interface FinishDeleteAccountViewModel {
    idUser: string;
    token: string;
}

interface RemoveExternalLoginInputModel {
    loginProvider: string;
    providerKey: string;
}