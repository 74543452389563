import React, { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import CmsContent from './CmsContent';
import MarkdownLinkButton from './MarkdownRenderers/MarkdownLinkButton';
import frontMatter from 'gray-matter';
import MarkdownVideoEmbed from './MarkdownRenderers/MarkdownVideoEmbed';

const codeRenderers = new Map<string, FunctionComponent<{ data: string }>>([
  ['link-button', MarkdownLinkButton],
  ['video-embed', MarkdownVideoEmbed]
]);

const MarkdownContent: FunctionComponent<Props> = (props) => {

  const renderers = {
    code: ({ language, value }) => {
      const Component = codeRenderers.get(language);
      return Component ? <Component data={value} /> : value;
    }
  };

  const source = frontMatter(props.body);
  return (
    <CmsContent>
      <ReactMarkdown source={source.content} escapeHtml={false} renderers={renderers} />
    </CmsContent>
  );
};

export interface Props {
  body: string;
}

export default MarkdownContent;